import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
  state: () => ({
    user: null,
    showLogin: false,
    isLogged: false,
  }),
  actions: {
    setUser(data) {
      this.user = data;
    },
  },
});
