<template>
  <div>
    <v-card class="mx-auto my-8 pa-4" max-width="344" outlined>
      <v-row align="center" no-gutters>
      
      <!-- Column for Text Content -->
      <v-col cols="8"> <!-- Adjust 'cols' value based on your layout needs -->
       
          <span class="caption">Für Tierärzte</span>
          <h3>Download Eisbaum-Tabelle 2024</h3>
       
      </v-col>
      
      <!-- Column for Avatar -->
      <v-col cols="4"> <!-- Adjust 'cols' value based on your layout needs -->
        <a href="/images/pdf/tabelle.pdf" target="_blank">
         
            <img src="/images/pdf/picto.png" style="width: 80px;" />
         
        </a>
      </v-col>

    </v-row>
      <v-btn
        to="/images/pdf/tabelle.pdf"
        target="_blank"
        density="compact"
        outlined
        class="nocaps mt-2 borderbtn"
        ><v-icon class="redtext mr-2">pets</v-icon> EisbaumTabelle | Hund</v-btn
      >

      <v-btn
        to="/images/pdf/Eisbaumtabelle_katze.pdf"
        target="_blank"
        density="compact"
        outlined
        class="nocaps mt-2 borderbtn"
        ><v-icon class="katzetext mr-2">pets</v-icon> EisbaumTabelle |
        Katze</v-btn
      >
    </v-card>

  </div>
</template>

<script setup>

</script>
