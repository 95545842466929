import http from "./http.js";

let apiUrl = "https://www.eisbaumtabelle.de";

if (
  window.location.hostname == "localhost" ||
  window.location.hostname == "loc.etb.de"
) {
  apiUrl = "https://loc.etb.de";
}

if (window.location.hostname == "www.eisbaumtabelle.de") {
  apiUrl = "https://www.eisbaumtabelle.de";
}

export default {
  postUser(payload) {
    return http.post(apiUrl + `/postuser`, { payload });
  },
  postTarif(payload) {
    return http.post(apiUrl + `/posttarif`, { payload });
  },
  postGuest(payload) {
    return http.post(apiUrl + `/postguest`, { payload });
  },
  postRechner(payload) {
    return http.post(apiUrl + `/postrechner`, { payload });
  },
};
