<template>
  <div>
   
  </div>
</template>

<script setup>
import { onMounted, getCurrentInstance, watch } from "vue";
import BaseCalls from "@/services/BaseCalls";
import { useRouter, useRoute } from "vue-router";
import { useUserStore } from "@/stores/user";
import { useLoaderStore } from "@/stores/loader";
const userStore = useUserStore();
const { proxy } = getCurrentInstance();
const router = useRouter();
const route = useRoute();
const loaderStore = useLoaderStore();

function setLoading(loading) {
  loaderStore.setLoader(loading);
}

async function checkLoginToken() {
  if (!route.params.token) {
    return false;
  }
  setLoading(true);
  let select = {
    token: route.params.token,
    action: "startlogintoken",
  };
  const resp = await BaseCalls.postUser(select);
  if (resp.data && resp.data.user) {
    userStore.setUser(resp.data.user);
    setLoading(false);
    router.push("/account");
  } else {
    setLoading(false);
  }
}

onMounted(() => {
  proxy.$updateDocumentHead(
    "Jetzt kosten Eisbaum Krankentabellen anfordern",
    "Jetzt kosten Eisbaum Krankentabellen anfordern"
  );

  // Überprüfen, ob token bereits vorhanden ist
  if (route.params.token) {
    checkLoginToken();
  }
});

// Watcher einrichten, der auf Änderungen von route.params.token reagiert
watch(
  () => route.params.token,
  (newToken) => {
    if (newToken) {
      checkLoginToken();
    }
  }
);
</script>
