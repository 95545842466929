<template>
  <div>
    <v-row class="mt-4">
      <v-col cols="12" md="8" >
        <h1 class="greytext">
          Ihr Ergebnis für:
          <span v-if="berechnung.ber_name != ''">{{ berechnung.ber_name }} | </span>
          <span v-if="berechnung.ber_tierart == 2" class="katzetext">Katze, {{rechnerStore.showBerAlter(berechnung.ber_alter)}} </span>
          <span v-if="berechnung.ber_tierart == 1" class="redtext">Hund,  {{rechnerStore.showBerAlter(berechnung.ber_alter)}}</span>
        </h1>
        <div :style="{ 'border-right': '4px solid ' + rechnerStore.showBerType(berechnung.ber_tierart, 'color') }">
          <v-row>
            <v-col cols="12" md="1" style="max-width:40px;">
              <v-icon class="text-green">label_important</v-icon>
            </v-col>
            <v-col cols="12" md="11" xs="12">
              <h3>
                AntragAnnahmecheck |
                <span style="font-weight:normal">&nbsp;{{rechnerStore.showBerType(berechnung.ber_tierart,"versicherungstyp")}}</span>
              </h3>

              <v-row class="mt-2">
                <v-col cols="12" md="2"><b>Positiv:</b></v-col>
                <v-col cols="12" md="10">
                  <b class="text-green">{{ tarife.length }} Tarife</b> sind beantragbar |
                  <a @click="showVorgaben = !showVorgaben">
                    diese Kriterien werden berücksichtigt
                  </a>
                  <RechnerVorgabe v-if="showVorgaben" :berechnung="berechnung" />
                </v-col>
              </v-row>

              <v-row class="my-0" v-if="tarifenot.length > 0">
                <v-col cols="12" md="2"><b>Negativ:</b></v-col>
                <v-col cols="12" md="10">
                  Bei
                  <span v-if="tarifenot.length > 1">
                    <b class="text-red">{{ tarifenot.length }} Tarifen</b>
                  </span>
                  <span v-if="tarifenot.length == 1">
                    <b class="text-red">diesem Tarif</b>
                  </span>
                  würde Ihr Antrag abgelehnt werden
                  <span v-if="tarifenot.length > 0">
                    <br />
                    <a @click="showNogo = !showNogo">
                      Lesen Sie hier wieso manche Tarife nicht abschließbar sind...
                    </a>
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-col>

      <v-col cols="12" md="4" class="mt-10">
        Hier finden Sie eine
        <a  @click="rechnerStore.setShowPopTarif(item, 'IndexNoVergleich')">
          Übersicht aller {{rechnerStore.showBerType(berechnung.ber_tierart, 'versicherungstyp')}}
        </a>
        die wir ausgewertet haben und welche Tarife bereits im Vergleichsrechner dargestellt werden.

        <v-dialog v-model="showNogo" v-if="tarifenot.length > 0" width="888">
       
          <v-card class="pa-4">
            <v-row class="mb-4">
          <v-col cols="11"></v-col>
          <v-col cols="1">
            <v-btn
              icon
              @click="showNogo =! showNogo"
            >
              <v-icon class="redtext">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <h2 class="text-red">Ergebnis AntragAnnahmecheck</h2>
            <h3>Folgende Tarife sind nicht abschließbar:</h3>
            <br />
            <v-row v-for="item in tarifenot" :key="item.tier_id">
              <v-col cols="12" md="12">
                <b>{{ item.un_name }} {{ item.tier_title }}</b>
                <ul>
                  <li v-for="info in item.nogoinfo" :key="info">{{ info }}</li>
                </ul>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import { ref, defineProps } from "vue";
import RechnerVorgabe from "./RechnerVorgabe.vue";
import { useBerechnungStore } from '@/stores/rechner';
const rechnerStore = useBerechnungStore();
const showVorgaben = ref(false);
const showNogo = ref(false);


const props = defineProps({
  tarifenot: {
    type: Array,
  },
  tarife: {
    type: Array,
  },
  berechnung: {
    type: Object,
  },
  error: {
    type: [String, Number],
  },
});

console.log(props.berechnung.ber_id);
</script>
