<template>
  <div>
    <v-card class="no-rounded-card mb-6 body-2 pr-2" outlined :style="stylecard">
      <v-row>
        <v-col cols="1" >
          <v-row>
            <v-col cols="12" >
              <div
                style="
                  padding: 12px;
                  background-color: #404040;
                  min-height: 40px;
                  min-width: 80px;
                  font-size: 24px;
                  color: white;
                "
              >
                #{{ tarif.ranking }}
              </div>

              <div
                :class="rechnerStore.showBerType(berechnung.ber_tierart, 'ground')"
                style="
                  padding: 12px;

                  min-height: 40px;
                  min-width: 80px;
                "
              >
                <a
                  style="
                    font-size: 24px;
                    color: white;
                    font-weight: bold;
                    cursor: pointer;
                  "
                  class="text-white"
                 @click="rechnerStore.setShowPopTarif(tarif, 'rechenweg')"
                  >{{ tarif.tier_wert }} %</a
                >
              </div>
            </v-col>
          </v-row>
        </v-col>
        <!-- Ende Oben Spalte 1-->
       <v-col cols="6">
          <h2><a>{{ tarif.tier_title }}</a></h2>
          <!-- Leistungen -->
          
                  <v-row>
                    <v-col cols="12" md="3">Durchschnitts-erstattung:</v-col>
                    <v-col cols="12" md="9"
                      ><b
                        ><a  @click="rechnerStore.setShowPopTarif(tarif, 'rechenweg')"
                          >{{ tarif.tier_wert }} %</a
                        ></b
                      >
                      |

                      <b v-if="tarif.empfehlung == 1" class="text-green"
                        >Tipp "Sehr gute Alternative"</b
                      >
                      <b v-if="tarif.empfehlung == 2" class="text-green"
                        >Tipp "Preis-Leistung"</b
                      >
                     
                    </v-col>
                  </v-row>
                  <v-row v-if="tarif.tier_wartezeit">
                    <v-col cols="12" md="3">Wartezeit</v-col>
                    <v-col cols="12" md="9"
                      ><b>{{ tarif.tier_wartezeit }}</b></v-col
                    >
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="3"
                      >Tierbehandlung<br />
                      Max. Erstattung pro Jahr</v-col
                    >
                    <v-col cols="12" md="9"
                      ><b>{{ tarif.tier_behandlung_erstattung_zahl }} </b>
                      <br />
                      max. Erstattung pro Jahr:
                      <b>{{ tarif.tier_behandlung_erstattung_max_jahr }}</b>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="3">Tieroperation:</v-col>
                    <v-col cols="12" md="9 "
                      ><b>{{ tarif.tier_op_erstattung_zahl }}</b> <br />
                      max. Erstattung pro Jahr:
                      <b>{{ tarif.tier_op_erstattung_max_jahr }}</b></v-col
                    >
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="3">Vorsorge pro Jahr </v-col>
                    <v-col cols="12" md="9"
                      ><b
                        v-if="
                          tarif.tier_beg_vorsorge == 0 &&
                            tarif.tarif_id != 4 &&
                            tarif.tarif_id != 5 &&
                            tarif.tarif_id != 6
                        "
                        class="text-red"
                        >keine Erstattung</b
                      >
                      <b
                        v-if="
                          (tarif.tier_beg_vorsorge == 0 &&
                            tarif.tarif_id == 4) ||
                            tarif.tarif_id == 5 ||
                            tarif.tarif_id == 6
                        "
                        >leistet für Vorsorge im Rahmen der Summenbegrenzungen
                        für Tierbehandlung</b
                      >
                      <b v-if="tarif.tier_beg_vorsorge > 0"
                        >{{ tarif.tier_beg_vorsorge }} €</b
                      >
                      (z.B. Impfungen, etc.)
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="3">
                      Alternative Behandlungs-methoden</v-col
                    >
                    <v-col cols="12" md="9 "
                      >Physiotherapie:
                      <b>{{ tarif.tier_behandlung_physio }}</b> <br />
                      Homöopathie:
                      <b>{{ tarif.tier_behandlung_homopathie }}</b> <br />
                      Akupunktur: <b>{{ tarif.tier_behandlung_akupunktur }}</b>
                    </v-col>
                  </v-row>
  <!-- LeistungenEnde -->
         </v-col>
         <v-col cols="2">
          <span v-if="tarif.tier_preis_est == 0">
                <h2>{{ tarif.preis }} €</h2>
              </span>
              <span v-if="tarif.tier_preis_est == 1">
                ca.
                <h2>{{ tarif.preis }} €</h2>
                <a @click="rechnerStore.setShowPopTarif(tarif, 'preis_est')">Wieso ca.?</a> <br />
              
              </span>
              monatlich
              <span v-if="tarif.zuschlag > 0"
                >Inkl. <b>{{ tarif.zuschlag }} €</b> Risiko-Zuschlag für
                Jagdnutzung</span
              >
         </v-col>
         <v-col cols="3">
          <v-card tile elevation="1" color="#f2f2f2">
    
        
            <v-card-text>
              <div style="text-align: center;">
                <h3 v-if="tarif.empfehlung > 0" class="redtext">Eisbaum-Empfehlung</h3>
                <h2 v-if="tarif.empfehlung == 1" class="redtext my-1">"Beste Leistung"</h2>
                <h2 v-if="tarif.empfehlung == 2" class="redtext my-1">"Alternativ-Empfehlung"</h2>
              </div>
              <ul class="mt-2">
                <li v-for="item in tarif.highlight" :key="item">
                  <span v-html="item"></span>
                </li>
             
              </ul>
            </v-card-text>
          </v-card>
          <v-btn v-if="tarif.un_abschluss_url != ''" class="nocaps text-white" @click="saveAntragClickend()" target="_blank"
              :href="tarif.un_abschluss_url" :color="rechnerStore.showBerType(berechnung.ber_tierart, 'color')" rounded="0" block><h3 class="text-white">Jetzt online beantragen</h3></v-btn>
          </v-col>
        </v-row>
        <!-- Ende Zeile oben -->
        <v-row class="mb-4 ml-2">
          <v-col cols="1" ></v-col>
        <v-col cols="11" >
        
          <v-btn
            class="nocaps mr-4"
            :color="rechnerStore.showBerType(berechnung.ber_tierart, 'color')"
            depressed
            tile
            style="font-size: 18px"
            v-if="tarif.un_abschluss_url != ''"
          ><b>
            <a
              @click="saveAntragClickend()"
              :href="tarif.un_abschluss_url"
              target="_blank"
              class="text-white"
              >Jetzt online beantragen
            </a></b>
          </v-btn>
          <v-btn
          @click="rechnerStore.setShowPopTarif(tarif, 'beschreibungpop')"
            class="nocaps mr-4 text-white"
            :color="rechnerStore.showBerType(berechnung.ber_tierart, 'color')"
          
            outlined
          >
            Leistungen
          </v-btn>
          <v-btn
            class="nocaps text-white"
              :color="rechnerStore.showBerType(berechnung.ber_tierart, 'color')"
            outlined
             @click="rechnerStore.setShowPopTarif(tarif, 'IndexDreiVergleich')"
          >
            3 Tarife im Vergleich
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

  </div>
</template>

<script setup>
import { defineProps,ref,onMounted } from "vue";
import { useBerechnungStore } from '@/stores/rechner';
import BaseCalls from "@/services/BaseCalls";

const rechnerStore = useBerechnungStore();
const props = defineProps({
  tarif: {
    type: Object,
    required: true,
  },
  berechnung: {
    Object
  }
});
const stylecard = ref('')
async function saveAntragClickend() {

let select = {
       action: "saveclickantrag",
       tier_id: props.tarif.tier_id,
       ber_id: props.berechnung.ber_id,
       name: props.tarif.tier_title,
     }
   await BaseCalls.postTarif(select); // Korrigiert, um .value zu verwenden

}
onMounted(() => {
  console.log(props.tarif)
  if (props.berechnung.ber_tierart == 1 && props.tarif.empfehlung > 0) {
    stylecard.value = 'min-height: 160px;border-right:4px solid #d84315;'
  }
  if (props.berechnung.ber_tierart == 2  && props.tarif.empfehlung > 0) {
    stylecard.value = 'min-height: 160px;border-right:4px solid #fecf0c;'
  }
})
</script>
