<template>
  <div>
    <v-row v-if="!user">
      <v-col cols="12" md="6">
        <v-card md12 xs12 class="pa-2 mt-6">
          <h3 class="title">
            Jetzt als Tierarzt registrieren | <span class="text-green">kostenlos</span>
          </h3>

          <v-form
            ref="insertForm"
            v-if="!insertErfolg"
            @submit.prevent="insertUser"
            class="pl-2"
          >
            <v-row class="mt-4">
              <v-col xs="12">
                <v-text-field
                  v-model="userdata.user_name"
                  background-color="white"
                  type="text"
                  label="Praxis-Name"
                  :append-icon="getIconSuccessText('user_name')"
                  :error="errors.user_name"
                  :error-messages="errors.user_name ? ['Bitte geben Sie Ihren Namen an.'] : []"
                />
              </v-col>
            </v-row>

            <v-row class="mt-4">
              <v-col xs="12">
                <v-text-field
                  v-model="userdata.user_email"
                  background-color="white"
                  type="text"
                  label="Ihre E-Mail"
                  :append-icon="getIconSuccess()"
                  :error="errors.user_email"
                  :error-messages="errors.user_email ? ['Bitte geben Sie eine gültige E-Mail an.'] : []"
                />
              </v-col>
            </v-row>

            <v-row class="mt-4">
              <v-col xs="8">
                <v-text-field
                  v-model="userdata.user_strasse"
                  background-color="white"
                  type="text"
                  label="Ihre Strasse"
                  :append-icon="getIconSuccessText('user_strasse')"
                  :error="errors.user_strasse"
                  :error-messages="errors.user_strasse ? ['Bitte geben Sie Ihre Straße an.'] : []"
                />
              </v-col>
              <v-col xs="4" class="ml-4">
                <v-text-field
                  v-model="userdata.user_hsnr"
                  background-color="white"
                  type="text"
                  label="Hausnummer"
                />
              </v-col>
            </v-row>

            <v-row class="mt-4">
              <v-col xs="4">
                <v-text-field
                  v-model="userdata.user_plz"
                  background-color="white"
                  type="text"
                  label="PLZ"
                  :append-icon="getIconSuccessText('user_plz')"
                  :error="errors.user_plz"
                  :error-messages="errors.user_plz ? ['Bitte geben Sie Ihre PLZ an.'] : []"
                />
              </v-col>
              <v-col xs="8" class="ml-2">
                <v-text-field
                  v-model="userdata.user_ort"
                  background-color="white"
                  type="text"
                  label="Ort"
                  :append-icon="getIconSuccessText('user_ort')"
                  :error="errors.user_ort"
                  :error-messages="errors.user_ort ? ['Bitte geben Sie Ihren Ort an.'] : []"
                />
              </v-col>
            </v-row>

            <!-- Password Fields -->
            <v-row class="mt-4">
              <v-col xs="12">
                <v-text-field
                  style="max-width: 280px"
                  v-model="userdata.user_pass_neu1"
                  background-color="white"
                  :type="showPwd1 ? 'password' : 'text'"
                  label="Ihr Passwort"
                  autocomplete="on"
                  :append-icon="showPwd1 ? 'mdi-eye-off' : 'mdi-eye'"
                  @click:append="showPwd1 = !showPwd1"
                  :error="errors.user_pass_neu1"
                  :error-messages="errors.user_pass_neu1 ? ['Bitte geben Sie Ihr neues Passwort an.'] : []"
                />
              </v-col>
            </v-row>

            <v-row class="mt-4">
              <v-col xs="12">
                <v-text-field
                  style="max-width: 280px"
                  v-model="userdata.user_pass_neu2"
                  background-color="white"
                  :type="showPwd2 ? 'password' : 'text'"
                  label="Wiederholen Sie Ihr Passwort"
                  autocomplete="on"
                  :append-icon="showPwd2 ? 'mdi-eye-off' : 'mdi-eye'"
                  @click:append="showPwd2 = !showPwd2"
                  :error="errors.user_pass_neu2"
                  :error-messages="errors.user_pass_neu2 ? ['Bitte wiederholen Sie Ihr neues Passwort.'] : []"
                />
              </v-col>
            </v-row>

            <v-row class="mt-2" justify="start">
              <v-col xs="12">
                <v-btn
                  @click="insertUser"
                  type="submit"
                  
                  color="#d84315"
                  class="nocaps"
                  style="color: white"
                >
                  Jetzt als Tierarzt registrieren
                </v-btn>
              </v-col>
            </v-row>

            <v-row v-if="error_step2" class="mt-2">
              <v-col xs="12">
                <div class="pa-2"
                  style="background-color: #d84315;">
                  <h3 class="subtitle text-white">
                    Achtung bei der Übertragung kam es zu einem Fehler
                  </h3>
                  <span class="text-white" v-html="error_step2_text"></span>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>

      <v-col cols="12" md="3">
        <div
          v-for="stimme in stimmen"
          :key="stimme.stim_id"
          class="pl-4 my-8"
          style="border-bottom:2px solid #fff;background-color:#f9f9f9"
        >
          <v-row>
            <v-col cols="12" md="10" style="vertical-align:top;">
              <h3>
                {{ stimme.stim_title }}
              </h3>
            </v-col>
            <v-col cols="12" md="2">
              <span v-if="stimme.stim_type == 'Facebook'">
                <v-icon style="font-size: 30px" class="text-blue">
                  mdi-facebook
                </v-icon>
              </span>
              <span v-else-if="stimme.stim_type == 'Email'">
                <v-icon style="font-size: 30px" class="text-grey">
                  mdi-account
                </v-icon>
              </span>
              <span v-else-if="stimme.stim_type == 'Presse'">
                <span v-if="stimme.stim_image == ''">
                  <v-icon style="font-size: 30px" class="text-grey">
                    mdi-mail
                  </v-icon>
                </span>
                <v-avatar
                  tile
                  size="40"
                  v-else
                >
                  <img
                    :src="
                      `https://www.eisbaumtabelle.de/images/${stimme.stim_image}`
                    "
                  />
                </v-avatar>
              </span>
            </v-col>
          </v-row>

          <em class="pa-2" v-if="!stimme.showalle">
            "{{ stimme.text }}...<a @click="stimme.showalle = !stimme.showalle">
              " lesen....</a
            >
          </em>
          <em class="pa-2" v-if="stimme.showalle">
            "{{ stimme.stim_text }}"
          </em>

          <br /><b>{{ stimme.stim_name }}</b> | {{ stimme.date }}
          <span v-if="stimme.stim_type == 'Presse'">
            |
            <a :href="`${stimme.stim_quelle}`" target="_blank"
              >Veröffentlichung anzeigen</a
            ><br />
          </span>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, getCurrentInstance } from "vue";
import BaseCalls from "@/services/BaseCalls";
import { useUserStore } from "@/stores/user";
import { useLoaderStore } from "@/stores/loader";

const userStore = useUserStore();
const loaderStore = useLoaderStore();

const user = ref(false);
const stimmen = ref(false);
const insertErfolg = ref(false);
const showPwd1 = ref(false);
const showPwd2 = ref(false);
const userdata = ref({
  action: "insertUser",
  user_name: "",
  user_email: "",
  user_strasse: "",
  user_hsnr: "",
  user_plz: "",
  user_ort: "",
  user_pass_neu1: "",
  user_pass_neu2: "",
});

const errors = ref({});
const error_step2 = ref(false);
const error_step2_text = ref("");

const insertForm = ref(null);

function setLoading(loading) {
  loaderStore.setLoader(loading);
}

// Korrigierte validateEmail-Funktion
function validateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

// Funktion, um Textfelder zu prüfen
function checkTextFeld(feld) {
  return userdata.value[feld] && userdata.value[feld].trim() !== '';
}

function checkForm() {
  let checkPassed = true;
  error_step2.value = false;
  error_step2_text.value = "";
  errors.value = {}; // Fehler zurücksetzen

  // Validierung für jedes Feld
  if (!userdata.value.user_name) {
    error_step2_text.value += "Bitte geben Sie Ihren Namen an.<br />";
    errors.value.user_name = true;
    checkPassed = false;
  }

  // Validierung der E-Mail
  if (!validateEmail(userdata.value.user_email)) {
    error_step2_text.value += "Bitte geben Sie eine gültige E-Mail an.<br />";
    errors.value.user_email = true;
    checkPassed = false;
  }

  // Weitere Feldvalidierungen...
  if (!userdata.value.user_strasse) {
    error_step2_text.value += "Bitte geben Sie Ihre Straße an.<br />";
    errors.value.user_strasse = true;
    checkPassed = false;
  }

  if (!userdata.value.user_plz) {
    error_step2_text.value += "Bitte geben Sie Ihre PLZ an.<br />";
    errors.value.user_plz = true;
    checkPassed = false;
  }

  if (!userdata.value.user_ort) {
    error_step2_text.value += "Bitte geben Sie Ihren Ort an.<br />";
    errors.value.user_ort = true;
    checkPassed = false;
  }

  // Validierung der Passwörter
  if (!userdata.value.user_pass_neu1) {
    error_step2_text.value += "Bitte geben Sie Ihr neues Passwort an.<br />";
    errors.value.user_pass_neu1 = true;
    checkPassed = false;
  }

  if (!userdata.value.user_pass_neu2) {
    error_step2_text.value += "Bitte wiederholen Sie Ihr neues Passwort.<br />";
    errors.value.user_pass_neu2 = true;
    checkPassed = false;
  }

  if (
    userdata.value.user_pass_neu1 &&
    userdata.value.user_pass_neu2 &&
    userdata.value.user_pass_neu1 !== userdata.value.user_pass_neu2
  ) {
    error_step2_text.value += "Ihre beiden Passwörter stimmen nicht überein.<br />";
    errors.value.user_pass_neu1 = true;
    errors.value.user_pass_neu2 = true;
    checkPassed = false;
  }

  if (!checkPassed) {
    error_step2.value = true;
  }

  return checkPassed;
}

function getIconSuccess() {
  let check = validateEmail(userdata.value.user_email);
  if (check === true) {
    return "done";
  } else {
    return "";
  }
}

function getIconSuccessText(feld) {
  let check = checkTextFeld(feld);
  if (check === true) {
    return "done";
  } else {
    return "";
  }
}

async function insertUser() {
  setLoading(true);

  if (!checkForm()) {
    setLoading(false);
    return;
  }

  userdata.value.action = "insertuser";

  try {
    const resp = await BaseCalls.postUser(userdata.value);
    if (resp.data.error === true) {
      error_step2_text.value = "Es existiert bereits ein User mit dieser E-Mail";
      error_step2.value = true;
      errors.value.user_email = true; // E-Mail-Feld als Fehler markieren
      setLoading(false);
    } else {
      if (resp.data.user) {
        userStore.user = resp.data.user;
        insertErfolg.value = true;
        window.location.href = "/account";
      } else {
        error_step2_text.value =
          "Fehler beim Speichern – bitte versuchen Sie es noch einmal.";
        error_step2.value = true;
        setLoading(false);
      }
    }
  } catch (err) {
    console.error(err);
    error_step2_text.value =
      "Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.";
    error_step2.value = true;
    setLoading(false);
  }
}

async function getStimmen() {
  let select = {
    action: "getStimmen",
  };
  const resp = await BaseCalls.postRechner(select);
  if (resp.data && resp.data.stimmen) {
    stimmen.value = resp.data.stimmen;
  }
}

watch(
  () => userStore.user,
  (newUser) => {
    user.value = newUser;
  }
);

onMounted(() => {
  const { proxy } = getCurrentInstance();
  getStimmen();
  proxy.$updateDocumentHead(
    "Jetzt kostenlos als Tierarzt registrieren und EisbaumTabellen anfordern",
    "Jetzt kostenlos als Tierarzt registrieren und EisbaumTabellen anfordern"
  );
});
</script>