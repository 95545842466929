<template>
  <div>
    <v-row>
      <v-col cols="12">
        <h2>{{ tarif.tier_title }}</h2>
        <v-btn
          v-if="tarif.un_abschluss_url != ''"
          class="nocaps my-4"
          style="background-color: #d84315; color: #fff"
        >
          <br />
          <a class="text-white"
            @click="saveAntragClickend()"
            :href="tarif.un_abschluss_url"
            target="_blank"
            style="color: #fff"
          >
            jetzt {{ tarif.un_name }} online beantragen
          </a>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-for="(item, index) in filteredOrigin" :key="item.value">
      <v-col
        cols="12"
        md="12"
        xs="12"
        v-if="!item.showintern && tarif[item.value]"
      >
        <b>{{ item.title }}</b><br />
        <span class="pb-0 mb-0">
          {{ fieldStore.showFieldComputed(item.value, tarif, 'hund') }}
        </span>
        <a
          v-if="item.spezialpop && item.spezialpop == 'rechenweg'"
          @click="rechnerStore.setShowPopTarif(tarif, 'rechenweg')"
        >
          &nbsp;&nbsp; mehr Infos...
        </a>
        <div class="dividersmall" />
      </v-col>

      <!-- Integrate the button after the 4th and 8th iteration -->
      <v-col cols="12" v-if="(index === 3 || index === 7 || index ===12 || index === 17 ) && tarif.un_abschluss_url != ''">
        <v-btn
          class="nocaps my-4"
          style="background-color: #d84315; color: #fff"
        >
          <br />
          <a
           class="text-white"
            @click="saveAntragClickend()"
            :href="tarif.un_abschluss_url"
            target="_blank"
            style="color: #fff"
          >
            jetzt {{ tarif.un_name }} online beantragen
          </a>
        </v-btn>
      </v-col>
    </v-row>
    <v-btn
      v-if="tarif.un_abschluss_url != ''"
      class="nocaps my-4"
      style="background-color: #d84315; color: #fff"
    >
      <br />
      <a
       class="text-white"
        @click="saveAntragClickend()"
        :href="tarif.un_abschluss_url"
        target="_blank"
        style="color: #fff"
      >
        jetzt {{ tarif.un_name }} online beantragen
      </a>
    </v-btn>
  </div>
</template>

<script setup>
import { defineProps, computed, ref } from "vue";
import { useFieldStore } from '@/stores/fields';
import BaseCalls from "@/services/BaseCalls";
import { useBerechnungStore } from '@/stores/rechner';
const rechnerStore = useBerechnungStore();
const fieldStore = useFieldStore();
const props = defineProps({
  tarif: {
    type: Object,
    required: true,
  },
});

const origin = ref(fieldStore.hund);
const filteredOrigin = computed(() => {
  return Object.values(origin.value).filter(item => {
    return !item.showintern && props.tarif[item.value];
  });
});
async function saveAntragClickend() {

let select = {
       action: "saveclickantrag",
       tier_id: props.tarif.tier_id,
       ber_id: props.berechnung.ber_id,
       name: props.tarif.tier_title,
     }
   await BaseCalls.postTarif(select); // Korrigiert, um .value zu verwenden

}
console.log(props.tarif)
</script>
