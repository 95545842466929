<template>
  <div>
    <v-card class="no-rounded-card mb-6 body-2 pr-2 pb-2" outlined :style="stylecard">
      <v-row>
        <v-col cols="3">
          <v-row>
            <v-col cols="12">
              <div
                style="
                  padding: 12px;
                  background-color: #404040;
                  min-height: 40px;
                  min-width: 64px;
                  font-size: 18px;
                  color: white;
                "
              >
                #{{ tarif.ranking }}
              </div>

              <div
                :class="rechnerStore.showBerType(berechnung.ber_tierart, 'ground')"
                style="
                  padding: 12px;
                  min-height: 40px;
                  min-width: 64px;
                "
              >
                <a
                  style="
                    font-size: 18px;
                    color: white;
                    font-weight: bold;
                    cursor: pointer;
                  "
                   class="text-white"
                  @click="rechnerStore.setShowPopTarif(tarif, 'rechenweg')"
                >
                  {{ tarif.tier_wert }} %
                </a>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <!-- Ende Oben Spalte 1-->
        <v-col cols="9">
          <h3>{{ tarif.tier_title }}</h3>
          <span v-if="tarif.tier_preis_est == 1">ca.</span>
          <b class="text-blue"> {{ tarif.preis }} € | monatlich</b>
          <span v-if="tarif.tier_preis_est == 1">
            <br />
            <a @click="rechnerStore.setShowPopTarif(tarif, 'preis_est')">Wieso ca.?</a> <br />
          </span>

          <span v-if="tarif.zuschlag > 0">
            <br />Inkl. <b>{{ tarif.zuschlag }} €</b> Risiko-Zuschlag für Jagdnutzung
          </span>
        </v-col>
      </v-row>
      <v-divider class="my-2"></v-divider>
      <!-- Leistungen-->
      <v-row>
        <v-col cols="1"></v-col>
        <v-col cols="11">
          <v-row>
            <v-col cols="12">
              Durchschnittserstattung:<br />
              <b>
                <a @click="rechnerStore.setShowPopTarif(tarif, 'rechenweg')">
                  {{ tarif.tier_wert }} %
                </a>
              </b>
              |
              <b v-if="tarif.empfehlung == 1" class="text-green"
                >Tipp "Sehr gute Alternative"</b
              >
              <b v-if="tarif.empfehlung == 2" class="text-green">Tipp "Preis-Leistung"</b>
            </v-col>
          </v-row>
          <v-row v-if="tarif.tier_wartezeit">
            <v-col cols="12">
              Wartezeit:<br />
              <b>{{ tarif.tier_wartezeit }}</b>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <b :style="{ color: rechnerStore.showBerType(berechnung.ber_tierart, 'color') }"
                >Tierbehandlung:</b
              ><br />
              Max. Erstattung pro Jahr
              <b>{{ tarif.tier_behandlung_erstattung_zahl }} </b>
              <br />
              max. Erstattung pro Jahr:
              <b>{{ tarif.tier_behandlung_erstattung_max_jahr }}</b>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              Tieroperation<br />
              <b>{{ tarif.tier_op_erstattung_zahl }}</b> <br />
              max. Erstattung pro Jahr:
              <b>{{ tarif.tier_op_erstattung_max_jahr }}</b>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              Vorsorge pro Jahr<br />
              <b
                v-if="
                  tarif.tier_beg_vorsorge == 0 &&
                  tarif.tarif_id != 4 &&
                  tarif.tarif_id != 5 &&
                  tarif.tarif_id != 6
                "
                class="text-red"
                >keine Erstattung</b
              >
              <b
                v-else-if="
                  tarif.tier_beg_vorsorge == 0 &&
                  (tarif.tarif_id == 4 || tarif.tarif_id == 5 || tarif.tarif_id == 6)
                "
                >leistet für Vorsorge im Rahmen der Summenbegrenzungen für Tierbehandlung</b
              >
              <b v-else>{{ tarif.tier_beg_vorsorge }} €</b>
              (z.B. Impfungen, etc.)
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <b :style="{ color: rechnerStore.showBerType(berechnung.ber_tierart, 'color') }"
                >Alternative Behandlungsmethoden</b
              ><br />
              Physiotherapie:
              <b>{{ tarif.tier_behandlung_physio }}</b> <br />
              Homöopathie:
              <b>{{ tarif.tier_behandlung_homopathie }}</b> <br />
              Akupunktur: <b>{{ tarif.tier_behandlung_akupunktur }}</b>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- LeistungenEnde-->
      <v-row>
        <v-col cols="1"></v-col>
        <v-col cols="11">
          <v-btn
            class="nocaps mb-4"
            :color="rechnerStore.showBerType(berechnung.ber_tierart, 'color')"
            depressed
            tile
            style="font-size: 18px"
            v-if="tarif.un_abschluss_url != ''"
          >
            <a
              @click="saveAntragClickend()"
              :href="tarif.un_abschluss_url"
              target="_blank"
              class="text-white"
            >
              Jetzt online beantragen
            </a>
          </v-btn>
          <br />
          <v-btn
            @click="rechnerStore.setShowPopTarif(tarif, 'beschreibungpop')"
            class="nocaps mr-4 text-white"
            :color="rechnerStore.showBerType(berechnung.ber_tierart, 'color')"
            outlined
          >
            Leistungen im Detail
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script setup>
import { defineProps, ref, onMounted } from "vue";
import { useBerechnungStore } from "@/stores/rechner";
import BaseCalls from "@/services/BaseCalls";

const rechnerStore = useBerechnungStore();

const props = defineProps({
  tarif: {
    type: Object,
    required: true,
  },
  berechnung: {
    type: Object,
    required: true,
  },
});

const stylecard = ref("");

async function saveAntragClickend() {
  const select = {
    action: "saveclickantrag",
    tier_id: props.tarif.tier_id,
    ber_id: props.berechnung.ber_id,
    name: props.tarif.tier_title,
  };
  await BaseCalls.postTarif(select);
}

onMounted(() => {
  console.log(props.tarif);
  if (props.berechnung.ber_tierart == 1 && props.tarif.empfehlung > 0) {
    stylecard.value = "min-height: 160px; border-right: 4px solid #d84315;";
  }
  if (props.berechnung.ber_tierart == 2 && props.tarif.empfehlung > 0) {
    stylecard.value = "min-height: 160px; border-right: 4px solid #fecf0c;";
  }
});
</script>