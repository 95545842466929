<template>
  <div>
    <v-row :style="{ 'border-bottom': '4px solid ' + rechnerStore.showBerType(berechnung.ber_tierart, 'color') }" class="mb-8">
      <v-col cols="12" md="12">
      <HeaderVergleich v-if="berechnung"  :tarifenot="tarifenot" :berechnung="berechnung" :tarife="tarife"/>
      <HeaderError v-if="error" :tarifenot="tarifenot" :error="error" />
      </v-col>
    </v-row> 
    <v-row>
      <v-col cols="12" md="12">
    <div v-if="tarife">
      <div v-for="item in tarife" :key="item.tier_id" class="mb-8">
        <CardDesktop
        v-if="!common.isMobile"
          :tarif="item"
          :berechnung="berechnung"
        />
        <CardMobile
        v-if="common.isMobile"
          :tarif="item"
          :berechnung="berechnung"
        />
      </div>
    </div>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import { ref,onMounted,getCurrentInstance } from "vue";
import { useCommonDataStore } from "@/stores/common";
import { useBerechnungStore } from '@/stores/rechner';
import BaseCalls from "@/services/BaseCalls";
import { useRoute} from "vue-router";
import HeaderVergleich from "@/components/rechner/HeaderVergleich.vue";
import HeaderError from "@/components/rechner/HeaderError.vue";
import CardDesktop from "@/components/rechner/CardDesktop.vue";
import CardMobile from "@/components/rechner/CardMobile.vue";
const route = useRoute();
const rechnerStore = useBerechnungStore();
const { proxy } = getCurrentInstance();
const common = useCommonDataStore();
import { useLoaderStore } from "@/stores/loader";
const loaderStore = useLoaderStore();

const tarife = ref(false);
const berechnung = ref(false);
const tarifenot = ref(false);
const tierart = ref("hund");
const error = ref(false);
const showBerechnungTierarzt = ref("Hunde-Krankenversicherung");

console.log(tierart.value);
function setLoading(loading) {
  loaderStore.setLoader(loading);
  }
async function getTarife() {
    setLoading(true);
    let select = {
      action:  "getBerechnungErgebnisToken",
      token: route.params.token
    };
   
    const resp = await BaseCalls.postRechner(select); // Korrigiert, um .value zu verwenden
    if (resp.data.error) {
          error.value = resp.data.error;
          tarifenot.value = resp.data.tarifenot;
          setLoading(false);
          } 
    if (resp.data && resp.data.tarife) {
        tarife.value = resp.data.tarife;
        tarifenot.value = resp.data.tarifenot;
        berechnung.value = resp.data.berechnung;
        if (berechnung.value.ber_tierart == 2) {
          showBerechnungTierarzt.value = "Katzen-Krankenversicherung";
        }
        rechnerStore.setBerechnung(berechnung.value);
        rechnerStore.setAlleTarife(tarife.value);
        proxy.$updateDocumentHead(
      "Ihre Eisbaum-Vergleich " + showBerechnungTierarzt.value,
      "Ihr Eisbaum Tarifvergleich"
    );
    setLoading(false);
      }
     
  }

onMounted(() => {
  getTarife();
  proxy.$updateDocumentHead(
      "Ihr Eisbaum Tarifvergleich",
      "Ihr Eisbaum Tarifvergleich"
    );
});
</script>
