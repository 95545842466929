<template>
   <div class="pa-4" style="max-width: 1200px">
    <v-row>
      <v-col :cols="common.isMobile ? 12 : 9" >
        <h1 class="ggfontbold greytext mt-4">
        Die besten
          <span style="font-weight:bold">Hunde-Krankenversicherungen</span> im   Test
        </h1>

     
        <ul class="ml-4 my-4">
          <li>
            Die
            <b class="ggfonthigh">besten Hunde-Krankenversicherungen {{common.getCurrentYear()}} </b>
            wissenschaftlich ausgewertet
          </li>
          <li>
            Diese Hunde-Tarife leisten für
            <b class="ggfonthigh">Tier-Operationen</b> und
            <b class="ggfonthigh">Tier-Behandlungen</b>
          </li>
          <li>
            Direkte Leistungsabrechnung bis zum
            <b class="ggfonthigh">4-fachen Satz der Gebührenordnung</b> für
            Tierärzte (GOT)
          </li>
        </ul>

        <div v-if="common.isMobile">
          <v-btn
            to="/rechner"
            outlined
            class="nocaps borderbtn mt-4"
          >
            <v-icon class="redtext mr-2">pets</v-icon> jetzt "Hunde-Vergleich"
            starten
          </v-btn>

          <v-btn
            to="/vergleich_katze"
            outlined
            class="nocaps my-4 borderbtn"
          >
            <v-icon class="katzetext mr-2">pets</v-icon> jetzt "Katzen-Vergleich"
            starten
          </v-btn>
        </div>
        In Zusammenarbeit mit Tierärzten und Tierkliniken haben wir die
        <b>EisbaumTabelle | 5-Jahres-Realwert-Methode</b>
        entwickelt. Das erste Testverfahren, das die Leistungsstärke einer
        Tier-Krankenversicherungen, auf Basis einer realen Behandlungs Historie
        analysiert. Berücksicht werden sowohl die Erstattungsprozentsätze für
        die wichtigsten Tiermedizinischen Leistungsbereiche, als auch
        tariflichen Begrenzungen. Das Ergebnis, der EisbaumIndex, ermöglicht es
        dem Hundebesitzer das reale Leistungspotential von
        Hundekrankenversicherungen unabhängig zu vergleichen und die richtige
        Krankenversicherung für seinen Hund zu finden.
        <br />
        <div class="divider my-8"></div>

        <v-row>
          <v-col cols="12" md="6">
            <h2 class="ggfontbold">
              <span>Hundekrankenversicherung</span>
            </h2>
            In nur 1 Minute erhalten Sie alle Leistungen und Preise der besten
            Hundekrankenversicherungen angezeigt. Starten Sie jetzt einen
            <a href="/rechner">kostenlosen Online-Vergleich</a>.<br />
    
                   <v-btn
       
          to="/rechner"
         
          outlined
          class="nocaps borderbtn mt-4"
          ><v-icon class="redtext mr-2">pets</v-icon> jetzt "Hunde-Vergleich"
          starten</v-btn
        >

            
            </v-col
          >
          <v-col cols="12" md="6">
            <h2 class="ggfontbold">
              <span>Katzenkrankenversicherung</span>
              <sup class="katzetext">Neu</sup>
            </h2>
            Der Eisbaum Online-Rechner sucht für Sie die leistungsstärksten
            Katzen-Krankenversicherungen.
            <a href="/vergleich_katze">"Katzen-Vergleich" starten</a> | <a href="/katze">EisbaumTabelle Katze</a>.<br />
   
                  <v-btn
     
          to="/vergleich_katze"
         
          outlined
          class="nocaps mt-4 borderbtn"
          ><v-icon class="katzetext mr-2">pets</v-icon> jetzt "Katzen-Vergleich"
          starten</v-btn
        >
          </v-col>
        </v-row>

        <div class="divider my-8"></div>
        <h2>Die neuesten Eisbaum-Vergleiche</h2>
        <span v-for="item in berechnungen" :key="item.ber_id" class="mr-6">

      <b v-if="item.ber_geschlecht == 2" class="text-pink">{{item.ber_name}}</b>
      <b v-if="item.ber_geschlecht == 1" >{{item.ber_name}}</b>
      , {{ item.ras_name }} ({{ getTimeAgo(item.ber_updated_date) }})
        </span>

        <div class="divider my-8"></div>


        <h2 class="ggfontbold">EisbaumTabelle | Hund &nbsp;&nbsp;&nbsp; <a href="/katze">EisbaumTabelle | Katze</a> <br><span style="font-weight:normal">Die Leistungsstärksten Hundekrankenversicherungen im Vergleich</span>
                      </h2>
        <v-row class="mt-2">
          <v-col cols="12" md="12">
 
              <div style="border-right:4px solid #d84315;" class="pr-3">
                <v-row class="mb-2" style="background-color: #f2f2f2;">
                  <v-col cols="2" v-if="!common.isMobile"  style="border-bottom:4px solid #d84315;">
                    <h3>Testsieger</h3>
                  </v-col>
                  <v-col cols="6" v-if="common.isMobile"  style="border-bottom:4px solid #d84315;">
                    <h3>Testsieger</h3>
                  </v-col>
                  <v-col cols="6" v-if="common.isMobile"  style="border-bottom:2px solid #d84315;">
                    <h3>Ø-Erstattung</h3>
                  </v-col>
                  <v-col cols="2" v-if="!common.isMobile"  style="border-bottom:2px solid #d84315;">
                    <h3>Ø-Erstattung</h3>
                  </v-col>
                  <v-col cols="3" v-if="!common.isMobile" style="border-bottom:2px solid #d84315;">
                    <h3>Tierbehandlung in %</h3>
                    <span class="smalltext">(jährliche Begrenzung in €)</span>
                  </v-col>
                  <v-col cols="3" v-if="!common.isMobile" style="border-bottom:2px solid #d84315;">
                    <h3>Tieroperationen in %</h3>
                    <span class="smalltext">(jährliche Begrenzung in €)</span>
                  </v-col>
                  <v-col cols="2" v-if="!common.isMobile" style="border-bottom:2px solid #d84315;">
                    <h3>mtl. Beitrag</h3>
                  </v-col>
                </v-row>

                <div v-for="(item, index) in tarife.slice(0, displayLimit)" :key="item.tier_id">
                  <v-row 
                    class="mb-2" 
                    style="vertical-align: top"
                    :style="index % 2 === 1 ? 'background-color: #f2f2f2;' : 'border-bottom:2px solid #f2f2f2;'"
                  >
                  <v-col cols="6"  v-if="common.isMobile">
                      <b>
                        <router-link :to="`/Krankenversicherung_Hund/${item.tier_url}`">
                          {{ item.tier_title }}
                        </router-link>
                      </b>
                    </v-col>
                    <v-col cols="6"  v-if="common.isMobile">
                      <b>
                        <a @click="rechnerStore.setShowPopTarif(item, 'rechenweg')">
                          {{ item.tier_wert }} %
                        </a>
                      </b>
                    </v-col>
                    <v-col cols="2"  v-if="!common.isMobile">
                      <b>
                        <router-link :to="`/Krankenversicherung_Hund/${item.tier_url}`">
                          {{ item.tier_title }}
                        </router-link>
                      </b>
                    </v-col>
                    <v-col cols="2"  v-if="!common.isMobile">
                      <b>
                        <a @click="rechnerStore.setShowPopTarif(item, 'rechenweg')">
                          {{ item.tier_wert }} %
                        </a>
                      </b>
                    </v-col>
                    <v-col cols="3" v-if="!common.isMobile">
                      <b>{{ item.tier_behandlung_erstattung_zahl }}</b>
                      <br />
                      <span class="smalltext">{{ item.tier_behandlung_erstattung_max_jahr }}</span>
                    </v-col>
                    <v-col cols="3" v-if="!common.isMobile">
                      <b>{{ item.tier_op_erstattung_zahl }}</b>
                      <br />
                      <span class="smalltext">{{ item.tier_op_erstattung_max_jahr }}</span>
                    </v-col>
                    <v-col cols="2" v-if="!common.isMobile">
                      <span v-if="item.tier_preis_est == 0">
                        <b>{{ item.preis }} €</b>
                      </span>
                      <span v-if="item.tier_preis_est == 1">
                        <b>ca. {{ item.preis }} €</b>
                        <br />
                        <a @click="rechnerStore.setShowPopTarif(item, 'preis_est')" class="smalltext">
                          Wieso ca.?
                        </a>
                      </span>
                    </v-col>
                  </v-row>
                </div>
                
                <div v-if="tarife.length > displayLimit" class="my-4">
                  <v-btn 
                    color="primary" 
                    variant="outlined" 
                    @click="showAllTarife"
                    class="px-4 smallcaps"
                  >
                    <v-icon left class="mr-2">mdi-chevron-down</v-icon>
                    {{ showAll ? 'Weniger anzeigen' : `${tarife.length - displayLimit} weitere Tarife anzeigen` }}
                  </v-btn>
                </div>

                <div v-if="showAll">
                  <div v-for="(item, index) in tarife.slice(displayLimit)" :key="item.tier_id">
                    <v-row 
                      class="mb-2" 
                      style="vertical-align: top"
                      :style="(index + displayLimit) % 2 === 1 ? 'background-color: #f2f2f2;' : 'border-bottom:2px solid #f2f2f2;'"
                    >
                      <v-col cols="6"  v-if="common.isMobile">
                        <b>
                          <router-link :to="`/Krankenversicherung_Hund/${item.tier_url}`">
                            {{ item.tier_title }}
                          </router-link>
                        </b>
                      </v-col>
                      <v-col cols="6"  v-if="common.isMobile">
                        <b>
                          <a @click="rechnerStore.setShowPopTarif(item, 'rechenweg')">
                            {{ item.tier_wert }} %
                          </a>
                        </b>
                      </v-col>
                      <v-col cols="2"  v-if="!common.isMobile">
                        <b>
                          <router-link :to="`/Krankenversicherung_Hund/${item.tier_url}`">
                            {{ item.tier_title }}
                          </router-link>
                        </b>
                      </v-col>
                      <v-col cols="2"  v-if="!common.isMobile">
                        <b>
                          <a @click="rechnerStore.setShowPopTarif(item, 'rechenweg')">
                            {{ item.tier_wert }} %
                          </a>
                        </b>
                      </v-col>
                      <v-col cols="3" v-if="!common.isMobile">
                        <b>{{ item.tier_behandlung_erstattung_zahl }}</b>
                        <br />
                        <span class="smalltext">{{ item.tier_behandlung_erstattung_max_jahr }}</span>
                      </v-col>
                      <v-col cols="3" v-if="!common.isMobile">
                        <b>{{ item.tier_op_erstattung_zahl }}</b>
                        <br />
                        <span class="smalltext">{{ item.tier_op_erstattung_max_jahr }}</span>
                      </v-col>
                      <v-col cols="2" v-if="!common.isMobile">
                        <span v-if="item.tier_preis_est == 0">
                          <b>{{ item.preis }} €</b>
                        </span>
                        <span v-if="item.tier_preis_est == 1">
                          <b>ca. {{ item.preis }} €</b>
                          <br />
                          <a @click="rechnerStore.setShowPopTarif(item, 'preis_est')" class="smalltext">
                            Wieso ca.?
                          </a>
                        </span>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </div>
    
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" class="mt-8">
            <h2>
              Hundekrankenversicherung | Die wichtigsten Themen auf einen Blick
            </h2>
            <ul class="ml-4 my-4">
              <li>
                <a href="/Schaeferhund"
                  ><span style="color: #d84315">Neu:</span> Eisbaumtabelle | Schäferhund ... die leistungsstärksten Tierkrankenversicherungen für Schäferhunde
                </a>
              </li>
              <li>
                <a href="/zahnsteinentfernung_hund"
                  >Diese
                  Hundekrankenversicherungen leisten auch Zahnsteinentfernungen
                </a>
              </li>
              <li>
                <a href="#Checkliste"
                  ><span style="color: #d84315">Checkliste</span> | Was sollte
                  man bei der Auswahl einer Hundekrankenversicherung unbedingt
                  beachten?</a
                >
              </li>
              <li>
                <a href="#Sinn"
                  >Warum der Abschluss einer Hundekrankenversicherung sinnvoll
                  ist?</a
                >
              </li>
              <li>
                <a href="#OP"
                  >4 Gründe | Warum eine Tier OP-Versicherung nicht genügend
                  Schutz bietet?</a
                >
              </li>
              <li>
                <a href="/Gebuehrenordnung_fuer_Tieraerzte"
                  >Diese
                  Hundekrankenversicherungen leisten nach der neuen GOT 3
                </a>
              </li>
              <li>
                <a href="/Urlaub_mit_Hund"
                  >Urlaub mit Hund | Hundekrankenversicherungen die auch im
                  Auslandsurlaub leisten
                </a>
              </li>
              <li>
                <a href="#Kosten"
                  >Wieviel kostet eine leistungstarke Tierkrankenversicherung
                  für Hunde?</a
                >
              </li>
              <li>
                <a href="#Allergie"
                  >Mein Hund ist Allergika | Ist er trotzdem versicherbar?</a
                >
              </li>
              <li>
                <a href="#Sofort"
                  >Tierkrankenversicherungen ohne Wartezeit, ohne
                  Selbstbeteiligung, mit laufender Behandlung</a
                >
              </li>
              <li>
                <a href="#EBW"
                  >EisbaumTabelle | 5-Jahres-Realwert-Methode - So haben wir
                  getestet</a
                >
              </li>
              <li>
                <a href="#EBW1"
                  ><span style="color: #d84315">Erklärvideo</span> |
                  5-Jahres-Realwert-Methode</a
                >
              </li>
              <li>
                <a href="#Physio"
                  >Physiotherapie, Homöopathie und Co. | Welche
                  Tierkrankenversicherungen leisten dafür?</a
                >
              </li>
            </ul>
          </v-col>
        </v-row>
        <div class="divider"></div>
        <v-row>
          <v-col cols="12" md="12" class="mt-8">
            <a name="Checkliste"></a>
            <h3>
              <span style="color: #d84315">Checkliste</span> |
              Krankenversicherung für Hunde - Diese 7 Fragen sollten Sie stellen
            </h3>
            <ol class="ml-4 my-4">
              <li>
                <b>Leistungsbereiche</b> - Erstattet die
                Hundekrankenversicherung sowohl Tierbehandlungskosten wie auch
                für Tieroperationskosten? Zudem sollte die jährliche
                Höchstleistung für diese Leistungsbereiche nicht zu stark
                begrenzt sein.
              </li>
              <li>
                <b>Selbstbeteiligung</b> - Wie hoch ist die Selbstbeteiligung
                der Hundekrankenversicherung? Die Selbstbeteiligung einer
                Krankenversicherung für Hunde sollte 20 % nicht übersteigen, da
                man sonst einen großen Teil der Kosten selbst tragen muss.
              </li>
              <li>
                <b>Annahmebedingungen</b> - Welche Voraussetzungen müssen
                erfüllt sein, dass ihr Hund von der Versicherung angenommen
                wird? Muss Ihr Hund gechipt, tätowiert oder kastriert sein?
              </li>
              <li>
                <b>Wartezeit vs. Sofortleistung</b> - Sieht die gewählte
                Tierkrankenversicherung eine Wartezeit vor oder leistet der
                Tarif vollumfänglich und sofort?
              </li>
              <li>
                <b>Anfängliche Begrenzungen</b> - Begrenzt die
                Hundekrankenversicherung die Erstattung in den ersten Jahren
                oder leistet der Tarif von Anfang an ohne Begrenzungen?
              </li>
              <li>
                <b
                  >Maximaler Abrechnungsfaktor Gebührenordnung für Tieraerzte
                  (GOT)</b
                >
                - Auf welchen Abrechnungsfaktor ist die Erstattung begrenzt? Je
                höher der Aufwand für eine Tierbehandlung ist, desto höher ist
                der Abrechnungsfaktor GOT (Gebührenordnung für Tierärzte) mit
                dem der Tierarzt die Behandlung abrechnet. Achten Sie darauf,
                dass die Tierkrankenversicherung diesen maximalen
                Abrechnungsfaktor nicht zu stark begrenzt. Eine Erstattung bis
                zum 3-fachen Satz nach GOT ist normal.
              </li>
              <li>
                <b>Alternative Behandlungsmethoden</b> für Ihren Hund - Wollen
                sie spezielle Leistungen wie Homöopathie oder Physiotherapie für
                Ihren Hund? Achten Sie darauf, dass die ausgewählte
                Hundekrankenversicherung auch dafür leistet.
              </li>
            </ol>
          </v-col>
        </v-row>
        <div class="divider"></div>
        <v-row class="mt-8">
          <v-col cols="12" md="12">
            <a name="Sinn"></a>
            <h3>
              Warum der Abschluss einer Krankenversicherung für Ihren Hund Sinn
              macht
            </h3>
            <ol class="ml-4 my-4">
              <li>
                <b>Hohe Kosten im Krankheitsfall.</b> Im Krankheitsfall oder bei
                Unfällen mit Tieren kommen oft sehr hohe Kosten auf den
                Hundehalter zu. So muss man beispielsweise bei einem Autounfall
                mit einem Hund, der eine Tier Op mit Narkose notwendig macht,
                mit mehreren tausend Euro für Op und Behandlungskosten rechnen.
                Heutzutage entwickeln Hunde immer häufiger Allergien, die
                behandelt werden müssen. Eine Bioresonanztherapie um diese
                Leiden Ihres Hundes zu lindern kann bis zu 1000 € kosten. Die
                Kosten für geplante Tieroperationen bei Krebserkrankungen können
                sich auf ca. 1.500 € - 3.500 €. Eine Physiotherapie für Ihren
                Hund stellt der Tierarzt oder Physiotherapeut mit mindestens 60
                € pro Stunde in Rechnung. Im Durchschnitt sind 5 Termine
                notwendig um die Schmerzen zu lindern und die notwendige
                Beweglichkeit wieder zu erreichen.
              </li>
              <li>
                <b>Regelmäßige Vorsorgeuntersuchungen sind Pflicht.</b>
                Diese werden von den meisten Hundekrankenversicherungen
                getragen. Zusätzlich stellen einige Versicherungen jährliche
                Gesundheitspauschalen für Impfungen und Wurmkuren zur Verfügung,
                die einen zusätzlichen Kostenfaktor für das Leben mit Hund
                darstellen.
              </li>
              <li>
                <b>Die beste Behandlung ist gerade gut genug.</b>Ihr Hund ist
                ein vollwertiges Familienmitglied und hat nur die beste
                Behandlung verdient. Deshalb nimmt man gerne ein paar hundert
                Euro mehr in Kauf um die Behandlung für den Vierbeiner
                erträglicher und besser zu machen.
              </li>
            </ol>
            <br />

            <v-card ref="" outlined elevation="1" class="mx-8 pa-4">
              <span style="color: #d84315"><b>Fazit:</b></span> Eine
              Krankenversicherung für Ihren Hund lohnt sich, wenn Sie eine gute
              Behandlung für Ihren Hund sicherstellen möchten ohne auf etwaige
              Kosten zu achten. Zudem macht eine Hundekrankenversicherung Sinn,
              wenn Sie auch bei einem Unfall oder einer plötzlich auftretende
              Krankheit abgesichert sein möchten.
              <br />
              <a href="/rechner"
                >Vergleichen Sie jetzt die leistungsstärksten
                Hundekrankenversicherungen</a
              >
            </v-card>
          </v-col>
        </v-row>

        <v-row class="mt-8">
          <v-col cols="12" md="12">
            <a name="OP"></a>
            <h3>
              4 Gründe | Warum die Leistungen einer reinen OP-Versicherung für
              Hunde nicht ausreichen
            </h3>
            <ol class="ml-4 my-4">
              <li>
                <b
                  >Eine OP Versicherung für Tiere leistet nicht für regelmäßige
                  Vorsorgeuntersuchungen beim Tierarzt</b
                ><br />
                Um Krankheiten Ihres Hundes zu vermeiden, frühzeitig zu erkennen
                und behandeln zu können sind regelmäßige Vorsorgeuntersuchungen
                und Prophylaxe (Parasitenvorsorge & Impfungen) beim Tierarzt
                entscheidend. Hierfür leistet nur eine Krankenversicherung für
                Hunde.
              </li>
              <li>
                <b
                  >Eine OP-Versicherung leistet nicht für Diagnosemaßnahmen die
                  nicht unmittelbar mit der Operation in Verbindung stehen.</b
                ><br />
                "Mein Hund frisst nichts und schläft viel" ist noch keine
                Diagnose. Um herauszufinden was meinem Tier wirklich fehlt, ist
                oft ein Tierarztbesuch und eine Reihe von Untersuchungen
                (Blutuntersuchungen, Röntgen, Ultraschall, etc.) notwendige.
                Erst dann kann eine verlässliche Diagnose gestellt werden um
                welche Krankheit es sich handelt und eine Tierbehandlung
                begonnen werden. Dafür leistet eine reine OP Versicherung Hund
                nicht.
              </li>
              <li>
                <b
                  >Nicht jede Krankheit Ihres Vierbeiners kann mit einer
                  Operation behandelt werden.</b
                ><br />
                Viele Krankheiten oder gesundheitliche Beschwerden Ihres Tieres,
                wie zum Beispiel Allergien, ergeben sich im Laufe der Zeit und
                müssen von einem Tierarzt begleitend oder immer wieder behandelt
                werden. Diese Kosten müssen Sie selbst tragen, wenn Ihr Tier mit
                einer reinen OP-Versicherung abgesichert ist.
              </li>
              <li>
                <b
                  >Nur Krankenvollversicherungen leisten für chronische
                  Erkrankungen wie zum Beispiel Diabetes bei Hunden</b
                >
              </li>
            </ol>
            <br />

            <v-card ref="" outlined elevation="1" class="mx-8 pa-4">
              <span style="color: #d84315"><b>Fazit:</b></span> Tier
              OP-Versicherungen sorgen dafür, dass Sie nicht die hohen Kosten
              einer Tier-Operation selbst tragen müssen. Dennoch greifen diese
              Versicherungen bei weitem zu kurz, da sie kostspielige Maßnahmen
              die zur Erkennung, Vermeidung und Behandlung von Krankheiten
              entscheidend sind nicht versichern. Krankenversicherungen für
              Hunde schon.
              <br />
              <ul>
                <li>
                  <a href="/Op_Versicherung_Hund_Katze">
                    Weitere Informationen zu OP Versicherungen für Hunde und
                    Katzen
                  </a>
                </li>
                <li>
                  <a href="/rechner"
                    >Wählen Sie deshalb einen umfassenden Krankenschutz für
                    Ihren Vierbeiner - Vergleichen Sie jetzt
                    Hundekrankenversicherungen.</a
                  >
                </li>
              </ul>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="mt-8">
          <v-col cols="12" md="12">
            <a name="Kosten"></a>
            <h3>
              Wieviel kostet eine leistungsstarke Tierkrankenversicherung für
              Hunde?
            </h3>
            Wieviel eine Krankenversicherung für Hunde monatlich kostet hängt
            von den folgenden Faktoren ab:
            <ul class="ml-4 my-4">
              <li>Alter und Rasse ihres Hundes</li>
              <li>Höhe der Selbstbeteiligung der Hundekrankenversicherung</li>
              <li>Leistungsstärke der Tierkrankenversicherung Hund</li>
            </ul>
            Im Durchschnitt kostet eine leistungsstarke Hundekrankenversicherung
            für einen einjährigen Mischling (ab 45 cm Schulterhöhe) monatlich
            zwischen 35 € und 70 €. Handelt es sich um einen besonders großen
            Hund oder einen Hund mit speziellem Auftrag, wie zum Beispiel einen
            Jagdhund oder Blindenhund steigt der Beitrag entsprechend an.
          </v-col>
        </v-row>
        <div class="divider"></div>
        <v-row class="mt-8">
          <v-col cols="12" md="12">
            <a name="Zeitpunkt"></a>
            <h3>
              Der Abschluss einer Hundekrankenversicherung im ersten Lebensjahr
              Ihres Hundes erspart Ärger und Geld.
            </h3>
            Solange der Hund noch jung ist sind etwaige Leiden und Krankheiten
            (z.B. Allergien bei Hunden) noch nicht erkannt und damit noch
            versicherbar. Sind die Leiden eines Hundes schon bekannt und wurde
            der Vierbeiner diesbezüglich schon behandelt, ist eine
            Mitversicherung dieser Gebrechen nicht mehr möglich. Des Weiteren
            haben viele Hundekrankenversicherungen ein Höchstaufnahmealter. Ist
            diese Höchstaufnahmealter überschritten, kann der Hund bei dieser
            Versicherung nicht mehr versichert werden. Zudem koppeln einige
            Versicherungen die Höhe der Leistung an das Lebensalter des Hundes.
            Fazit: Schließen Sie die Krankenversicherungen für Ihren Hund
            frühzeitig ab und ersparen Sie sich so viel Geld und Ärger.
          </v-col>
        </v-row>
        <div class="divider"></div>
        <v-row class="mt-8">
          <v-col cols="12" md="12">
            <a name="Allergie"></a>
            <h3>Hund mit Allergie - Kann mein Hund versichert werden?</h3>
            Immer mehr Hunde entwickeln heutzutage die unterschiedlichsten
            Allergien. Angefangen von der Flohallergie, über Umweltallergien wie
            z.B. Gräser- und Pollen-Allergie oder Hausstauballergie bis hin zur
            Futtermittelallergie. Einige Hunderassen sind für bestimmte
            Hundeallergien anfälliger als andere. <br />
            Woran erkenne ich, dass mein Hund an einer Allergie leidet?
            Anzeichen dafür, dass Ihr Hund an einer Allergie leidet können
            verstärktes Kratzen, Schlecken und auch Wälzen sein, aber auch
            Durchfall, Erbrechen oder Blähungen können Anzeichen einer Allergie
            bei Hunden sein.<br />
            Um eine Hunde Allergie zu behandeln, muss zuerst festgestellt werden
            um welche Allergie es sich handelt. Hierfür stehen unterschiedliche
            Methoden der Diagnose zur Verfügung, wie zum Beispiel
            Blutuntersuchungen oder Ausschlussdiäten bei Futtermittelallergien.
            Wenn klar ist, um welche Hunde Allergie es sich handelt, kann diese
            mit klassischen Behandlungsmethoden wie konsequenter Prophylaxe und
            gezielter Medikation behandelt werden. Auch Alternative
            Behandlungsmethoden wie Homöopathie oder Bioresonanztherapie finden
            immer mehr Einzug in der Behandlung von Tier-Allergien.<br />
            Diese Behandlungen sind oft kostspielig und langwierig. Um nicht die
            kompletten Kosten einer Allergie Behandlung selbst zu tragen, ist es
            sinnvoll eine Hundeversicherung abschließen, die die Kosten für eine
            Allergiebehandlung trägt.<br /><br />

            <v-card ref="" outlined elevation="1" class="mx-8 pa-4">
              <span style="color: #d84315"><b>Fazit:</b></span> Wird bei Ihrem
              Hund vor Versicherungsabschluss eine Allergie diagnostiziert,
              zahlt keine Versicherung mehr für die Kosten einer Behandlung.
              Deshalb sollten Sie bereits in den ersten 6 Lebensmonaten eine
              Hundekrankenversicherung für Ihren Vierbeiner abschließen. Nur in
              seltenen Fällen tritt eine Allergie in den ersten 6 Lebensmonaten
              eines Hundes auf.
            </v-card>
          </v-col>
        </v-row>
        <div class="divider"></div>
        <v-row class="mt-8">
          <v-col cols="12" md="12">
            <a name="Sofort"></a>
            <h3>
              Gibt es Hundekrankenversicherungen ohne Wartezeit mit
              Sofortleistung?
            </h3>
            Ja, es gibt Hundekrankenversicherungen mit Sofortleistung und ohne
            Wartezeit. Allerdings sieht ein großer Teil der
            Tierkrankenversicherungen für Hunde eine Wartezeit vor. Diese
            Wartezeit für generelle Leistungen variiert je nach Hunde-Tarif
            zwischen 30 Tagen und 3 Monaten und entfällt in den meisten Fällen
            bei Unfall. Zudem sehen einige Tierkrankenversicherungs-Tarife
            zusätzliche Wartezeiten für spezielle Behandlungen oder Erkrankungen
            vor, wie zum Beispiel Tiergeburten, angeborene Fehlentwicklungen
            oder Teilprothesen.
            <br />
            <br />

            <v-card ref="" outlined elevation="1" class="mx-8 pa-4">
              <span style="color: #d84315"><b>Tipp:</b></span> Sie sollten bei
              der Auswahl der richtigen Tierkrankenversicherung für Ihren Hund
              nicht nur auf die Wartezeit achten, sondern auch auf anfängliche
              Leistungsbegrenzungen, die die Leistungen in den ersten Monaten
              auf einen bestimmten Höchstbetrag begrenzen.
            </v-card>
          </v-col>
        </v-row>

        <v-row class="mt-8">
          <v-col cols="12" md="12">
            <a name="Selbst"></a>
            <h3>
              Hundekrankenversicherungen ohne Selbstbeteiligung - Gibt es das?
            </h3>
            Ja, auch wenn ein großer Teil der Hundekrankenversicherungs-Tarife
            eine Selbstbeteiligung standardmäßig vorsieht, gibt es auch Tarife
            die ganz auf eine Selbstbeteiligung des Hundehalters verzichten.
            Hinweis: Auch Tarife ohne Selbstbeteiligung können ihre jährliche
            Leistung auf einen maximalen Euro-Betrag begrenzen. Diese
            Krankenversicherungen für Hunde leisten tarifgemäß 100 % für
            Tierbehandlungen und Tieroperationen ohne eine jährliche
            Begrenzung:<br />
            <ul class="ml-4 my-4">
              <li>
                <a
                  href="/Krankenversicherung_Hund/Barmenia_Tierkranken_Premium_100"
                  >Barmenia Tierkrankenversicherung Premium 100</a
                >
              </li>
              <li>
                <a href="/Krankenversicherung_Hund/GHV-OP_KV-Premium-100"
                  >GHV OP_KV-Premium-100</a
                >
              </li>
            </ul>
          </v-col>
        </v-row>
        <div class="divider"></div>
        <v-row class="mt-8">
          <v-col cols="12" md="12">
            <a name="Laufend"></a>
            <h3>
              Kann man eine Hunde-Krankenversicherung abschließen, wenn sich der
              Hund gerade in einer laufenden Behandlung befindet?
            </h3>
            Auch bei Tierkrankenversicherung für Hunde können bereits
            eingetretene Schäden, oder vor Versicherungsabschluss bekannte
            und/oder diagnostizierte Erkrankungen oder laufende Behandlungen
            nicht mehr versichert werden, da der Versicherungsfall dann bereits
            vor Abschluss eingetreten ist. Befindet sich Ihr Hund gerade in
            einer laufenden Behandlung, sollten Sie diese erfolgreich beenden
            und erst dann eine Hunde Krankenversicherung abschließen.<br /><br />
            <v-card ref="" outlined elevation="1" class="mx-8 pa-4">
              <span style="color: #d84315"><b>Fazit:</b></span> Tarife ohne
              Gesundheitsfragen lassen sich trotz laufender Behandlungen oder
              bereits diagnostizierter Erkrankung abschließen. Allerdings sind
              auch hier bereits vor Versicherungsabschluss bestehende
              Erkrankungen oder laufende Behandlungen nicht mitversichert.
            </v-card>
          </v-col>
        </v-row>

        <v-row class="mt-8">
          <v-col cols="12" md="12">
            <a name="EBW"></a>
            <h3>
              EisbaumTabelle | 5-Jahres-Realwert-Methode - So haben wir
              getestet!
            </h3>
            Mit der EisbaumTabelle | 5-Jahres-Realwert-Methode bietet
            EisbaumTabelle das erste Bewertungsverfahren für
            Tier-Krankenversicherungen, das auf Basis einer realen
            Behandlungs-Historie die durchschnittliche Erstattung von
            Tier-Krankenversicherungen in Prozent ermittelt.<br /><br />
            <strong>Reale Behandlungs-Historie</strong><br />
            Anhand der realen Behandlungs-Historie eines 1-jährigen,
            mittelgroßen Labrador Retriever, haben wir die durchschnittliche
            Leistungsstärke für jede der derzeit 25 Hundekrankenversicherungen
            berechnet.<br />
            Die zugrundeliegende Behandlungs-Historie umfasst in einen Zeitraum
            von 5 Jahren, sowohl Tierärztliche Behandlung (z.B. Physiotherapie
            Hund, Diagnose und Behandlung einer Hausstaubmilben und Futtermilben
            Allergie), als auch Tier-Operationen (Tumor-Operation mit
            Tierklinik-Aufenthalt) mit Tierarztrechnungen in Höhe von 5.260,-
            €.<br /><br />
            <strong>Leistungsbegrenzungen</strong><br />
            Eine weitere Besonderheit unseres Bewertungsverfahrens ist, dass
            neben reinen Prozentsätzen auch spezielle und teilweise versteckte,
            Leistungsbegrenzungen berücksichtigt werden. Zu hohe
            Leistungsversprechen und versteckte Leistungsbegrenzungen werden
            sichtbar und können bei der Auswahl einer Hundekrankenversicherung
            vom Tierbesitzer berücksichtigt werden.
            <br />
            <a href="/images/pdf/Erstattungsbeispiel.pdf"
              >>Erstattungsbeispiel aus der Praxis - Barmenia
              Tier-Krankenversicherung Top 90</a
            >
            <br />
            <br />
            <a name="EBW1"></a>
            <strong>Erklärvideo | 5-Jahres-Realwert-Methode</strong>
            <br />
            In diesem Erklärvideo erfahren Sie, wie Ihnen die EisbaumTabelle |
            5-Jahres-Realwert-Methode hilft die passende Krankenversicherungen
            für Ihren Hund zu finden.<br />
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/HPHbZmVYp7k"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            >
            </iframe>
            <br />
          </v-col>
        </v-row>
        <div class="divider"></div>
        <v-row class="mt-8">
          <v-col cols="12" md="12">
            <a name="Physio"></a>
            <h3>
              Physiotherapie Hund - Welche Hundekrankenversicherungen leisten
              für eine Physiotherapie für Hunde?
            </h3>
            Wie beim Menschen wird eine Physiotherapie für Hunde immer dann
            empfohlen wenn eine Erkrankung des Bewegungsapparates vorliegt. Ob
            als vorbeugende Maßnahme bei Beschwerden mit Gelenken (z.B. bei
            Arthrose oder Dysplasien) oder als Rehabilitationsmaßnahme nach der
            Operation eines Hundes, die Physiotherapie für Hunde findet
            vielseitige Anwendung in der Tiermedizin. Aufwendige
            Behandlungsmethoden und spezielle Geräte und Hilfsmittel (z.B.
            Unterwasserlaufband), die für die Physiotherapie Übungen mit Hunden
            benötigt werden treiben die Kosten für die Physiotherapie für Hunde
            in die Höhe. So können sich die Kosten für eine Hunde-Physiotherapie
            je nach lokalen Preisniveau auf 50 € - 80 € pro Behandlungstermin
            (im Durchschnitt 45 min) belaufen. Zudem sollte man berücksichtigen,
            dass zumeist mehrere Behandlungen für die Verbesserung der
            Beschwerden notwendig sind. Außerdem können zusätzliche Kosten im
            Rahmen einer Physiotherapie auf den Hundehalter zukommen, wie zum
            Beispiel Kosten für die Erstuntersuchung oder Anfahrt des Tier
            Therapeuten. Diese Hundekrankenversicherungen leisten für
            Physiotherapie für Hunde:
            <br />
            <ul class="ml-4 my-4">
              <li>
                <a href="/Krankenversicherung_Hund/GHV-OP_KV-Premium-100"
                  >GHV Tarife</a
                >
              </li>
              <li>
                <a
                  href="/Krankenversicherung_Hund/Barmenia_Tierkranken_Premium_100"
                  >Barmenia Tarife</a
                >
              </li>
              <li>
                <a href="/Krankenversicherung_Hund/Petplan_Plus"
                  >Petplan plus</a
                >
              </li>
              <li>
                <a
                  href="/Krankenversicherung_Hund/Agila_Tierkrankenschutz_Exklusiv"
                  >Agilia Tarife</a
                >
              </li>
              <li>
                <a
                  href="/Krankenversicherung_Hund/Dfv_Tierkrankenschutz_Exclusiv"
                  >DFV Tarife</a
                >
              </li>
            </ul>
          </v-col>
        </v-row>
        <div class="divider"></div>
        <v-row class="mt-8">
          <v-col cols="12" md="12">
            <a name="Der_Sinn"></a>
            <h3>
              Homöopathie Hund - Welche Hundekrankenversicherung zahlen für
              Globuli für Hunde?
            </h3>
            Die Homöopathie hat mittlerweile auch in der Tiermedizin Einzug
            gehalten. Die Einsatzgebiete für die Homöopathie für Hunde sind
            vielseitig. Sie reichen von leichten Gesundheitsstörungen wie z.B.
            Durchfall, Juckreiz und Maulgeruch bis hin zu Beschwerden mit
            Gelenken und übermäßigen Geschlechtstrieb. Auch bei akuten und
            chronischen Krankheiten kann Homöopathie für Hunde helfen die
            gesundheitlichen Beschwerden zu lindern. Für eine homöopathische
            Erstanamnese (die im Durchschnitt 1,5 Stunden in Anspruch nimmt) mit
            Arzneimittelverordnung belaufen sich die Kosten auf ca. 150€ - 300
            €. Fazit: Sie möchten für Ihren Hund eine schonende
            naturheilkundliche Behandlung gewährleisten und setzten dabei auf
            Homöopathie für Hunde? Dann sollten Sie eine
            Hundekrankenversicherung wählen, die im Bereich Homöopathie Hund
            umfassend leistet.
          </v-col>
        </v-row>
        <div class="divider"></div>
        <v-row class="mt-8">
          <v-col cols="12" md="12">
            <a name="Stiftung"></a>
            <h3>
              Die besten Hundekrankenversicherungen | Stiftung Warentest
              Testsieger 09/2021
            </h3>
            Der neue Stiftung Warentest / Finanztest 09/2021 ist da. In diesem
            vergleicht Stiftung Warentest 61 Operationskostenversicherungen für
            Hunde und 65 Krankenvollversicherungen für Hunde.<br />
            Im Gegensatz zur klassischen Schulnoten Bewertung, bewertet Stiftung
            Warentest die Versicherungen im aktuellen Vergleich nach
            Leistungsniveau. Hier wird in 4 Kategorien, sehr hoch, hoch, mittel
            und niedrig unterschieden.<br />
            Im Bereich Krankenversicherung Hund wurden von 65 Tarifen 52 Tarife
            mit einem sehr hohen bis hohen Leistungsniveau ausgezeichnet. Unter
            anderen wurde der Tarif Barmenia Kranken Premium 100% und der Tarif
            Helvetia PetCare Komfort mit sehr hoher Leistungsstärke
            ausgezeichnet. <br />Stiftung Warentest kommt zu dem Urteil, dass es
            für Hundebesitzer sinnvoll ist sich frühzeitig um eine Versicherung
            zu kümmern um hohe Tierarztrechnungen nicht komplett alleine
            bezahlen zu müssen.
          </v-col>
        </v-row>
        <div class="divider"></div>
        <v-row class="mt-8">
          <v-col cols="12" md="12">
            <a name="Stiftung"></a>
            <h3>
              Die Bundestierärztekammer e.V. empfiehlt Tierhaltern den Abschluss
              einer Tierkrankenversicherung
            </h3>
            Laut Bundestierärztekammer (BTK) unterschätzen viele Tierhalter die
            Behandlungskosten für ihr Tier im Krankheitsfall. Um eine gute
            tierärztliche Versorgung jederzeit zu gewährleisten, empfiehlt die
            Bundestierärztekammer den Abschluss einer passenden
            Tierkrankenversicherung.<br /><br />
            <strong
              >Folgende Leistungen sollten laut BTK im Versicherungsschutz
              enthalten sein:</strong
            >
            <ol class="ml-4 my-4">
              <li>Freie Wahl des Tierarztes</li>
              <li>
                Die Therapiefreiheit des Tierarztes sollte gewährleistet sein
              </li>
              <li>
                Tieroperationen und Nachsorge sollten ein Teil des
                Leistungsspektrums sein
              </li>
              <li>
                Regelmäßige Prophylaxemaßnahmen und Gesundheitschecks sollten
                enthalten sein
              </li>
              <li>
                Die Versicherung sollte bis zum 3-fachen Satz der
                Gebührenordnung für Tierärzte (GOT) leisten
              </li>
              <li>
                Etwaige Leistungsausschlüsse sollten transparent dargestellt
                werden
              </li>
            </ol>

            Quelle BTK:
            <a
              href="https://www.bundestieraerztekammer.de/presse/2019/08/Tierkrankenversicherung.php"
              >Unterschätzte Behandlungskosten – Wie soll ich das nur
              bezahlen?</a
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="3"  v-show="!common.isMobile" style="">
      <MenueDownload/>

        <div v-for="stimme in stimmen" :key="stimme.stim_id" class="pl-4 my-8" style="border-bottom:2px solid #fff;background-color:#f9f9f9">
          <v-row>
            <v-col cols="12" md="10" style="vertical-align:top;">
              <h3>
                {{ stimme.stim_title }}
              </h3>
            </v-col>
            <v-col cols="12" md="2">
              <span v-if="stimme.stim_type == 'Facebook'">
                <v-icon style="font-size: 30px" class="text-blue">
                  mdi-facebook
                </v-icon>
              </span>
              <span v-if="stimme.stim_type == 'Email'">
                <v-icon style="font-size: 30px" class="text-grey">
                  mdi-account
                </v-icon>
              </span>
              <span v-if="stimme.stim_type == 'Presse'">
                <span v-if="stimme.stim_image == ''">
                  <v-icon style="font-size: 30px" class="text-grey">
                    mdi-mail
                  </v-icon>
                </span>
                <v-avatar tile size="40" v-if="stimme.stim_image != ''">
                  <img :src="`https://www.eisbaumtabelle.de/images/${stimme.stim_image}`" />
                </v-avatar>
              </span>
            </v-col>
          </v-row>

          <em class="pa-2" v-if="!stimme.showalle">
            "{{ stimme.text }}...<a @click="stimme.showalle = !stimme.showalle">
             " lesen....</a
            >
          </em>
          <em class="pa-2" v-if="stimme.showalle">
            "{{ stimme.stim_text }}""
          </em>

          <br /><b>{{ stimme.stim_name }}</b> | {{ stimme.date }}
          <span v-if="stimme.stim_type == 'Presse'">
            |
            <a :href="`${stimme.stim_quelle}`" target="_blank"
              >Veröffentlichung anzeigen</a
            ><br />
          </span>

        </div>

        <v-row>
<a href="/Schaeferhund">
          <img
    :src="require('@/assets/schaeferhund.png')"
    style="width: 240px; float: left;  margin-bottom: 20px;"
  /></a>
          <!-- Labrador Card -->
          <v-col cols="12" md="12" class="my-4">
            <v-card class="mx-auto" outlined>
              <v-card-text>
                <v-row align="center">
                  <v-col cols="auto">
                    <v-avatar tile size="80">
                      <img src="/images/hunde/labrador.png" />
                    </v-avatar>
                  </v-col>
                  <v-col>
                    <h3>Labrador</h3>
                    <div>von 27,60 € bis 79,19 € mtl</div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="py-1" style="background-color:#cccca4">
                <a
                  href="/vergleich/19cc9bb34c8b8e702a41e9c83753569d4d0e3e2b8b4f8beb524930d1b64421de"
                  class="text-white"
                >
                  Tarifvergleich anzeigen
                </a>
              </v-card-actions>
            </v-card>
          </v-col>

          <!-- Dackel Card -->
          <v-col cols="12" md="12" class="my-4">
            <v-card class="mx-auto" outlined>
              <v-card-text>
                <v-row align="center">
                  <v-col cols="auto">
                    <v-avatar tile size="80">
                      <img src="/images/hunde/dackel.png" />
                    </v-avatar>
                  </v-col>
                  <v-col>
                    <h3 style="color:#7d3e28">Dackel</h3>
                    <div>von 21,75 € bis 63,50 € mtl</div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="py-1" style="background-color:#7d3e28">
                <a
                  href="/vergleich/9e0b359eab4962e642ff5009258e25a328070e72991e4f131ff2bab00ad846cf"
                  class="text-white"
                >
                  Tarifvergleich anzeigen
                </a>
              </v-card-actions>
            </v-card>
          </v-col>

          <!-- Schnauzer Card -->
          <v-col cols="12" md="12" class="my-4">
            <v-card class="mx-auto" outlined>
              <v-card-text>
                <v-row align="center">
                  <v-col cols="auto">
                    <v-avatar tile size="80">
                      <img src="/images/hunde/schnauzer.png" />
                    </v-avatar>
                  </v-col>
                  <v-col>
                    <h3>Schnauzer</h3>
                    <div>von 22,92 € bis 70,51 € mtl</div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="py-1" style="background-color:#8e77b5">
                <a
                  href="/vergleich/631563de6189e913f9c82c04da1c0b1fb3bb5b8bbb2310d976b04bf79d179f1e"
                  class="text-white"
                >
                  Tarifvergleich anzeigen
                </a>
              </v-card-actions>
            </v-card>
          </v-col>

          <!-- Boxer Card -->
          <v-col cols="12" md="12" class="my-4">
            <v-card class="mx-auto" outlined>
              <v-card-text>
                <v-row align="center">
                  <v-col cols="auto">
                    <v-avatar tile size="80">
                      <img src="/images/hunde/boxer.png" />
                    </v-avatar>
                  </v-col>
                  <v-col>
                    <h3>Boxer</h3>
                    <div>von 27,60 € bis 79,19 € mtl</div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="py-1" style="background-color:#7f8445">
                <a
                  href="/vergleich/0b9443a8cfe783e02fc6eb5d2251a6eab1155b335965c8fc924715ed33c0619e"
                  class="text-white"
                >
                  Tarifvergleich anzeigen
                </a>
              </v-card-actions>
            </v-card>
          </v-col>

          <!-- Pudel Card -->
          <v-col cols="12" md="12" class="my-4">
            <v-card class="mx-auto" outlined>
              <v-card-text>
                <v-row align="center">
                  <v-col cols="auto">
                    <v-avatar tile size="80">
                      <img src="/images/hunde/pudel.png" />
                    </v-avatar>
                  </v-col>
                  <v-col>
                    <h3>Pudel</h3>
                    <div>von 21,75 € bis 63,50 € mtl</div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="py-1" style="background-color:#e9946a">
                <a
                  href="/vergleich/05faa1dc41598c9159a2ab43709dbec31bf1d5da99a1a172a5e180dc394f0529"
                  class="text-white"
                >
                  Tarifvergleich anzeigen
                </a>
              </v-card-actions>
            </v-card>
          </v-col>

          <!-- Bulldogge Card -->
          <v-col cols="12" md="12" class="my-4">
            <v-card class="mx-auto" outlined>
              <v-card-text>
                <v-row align="center">
                  <v-col cols="auto">
                    <v-avatar tile size="80">
                      <img src="/images/hunde/bulldogge.png" />
                    </v-avatar>
                  </v-col>
                  <v-col>
                    <h3>Bulldogge</h3>
                    <div>von 27,60 € bis 79,19 € mtl</div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="py-1" style="background-color:#5f534a">
                <a
                  href="/vergleich/f0ac9a748a0b716cba8f4f47eb3caaf51cd1b5b7e6e368d9fc5e6b1482c5ec03"
                  class="text-white"
                >
                  Tarifvergleich anzeigen
                </a>
              </v-card-actions>
            </v-card>
          </v-col>

          <!-- Husky Card -->
          <v-col cols="12" md="12" class="my-4">
            <v-card class="mx-auto" outlined>
              <v-card-text>
                <v-row align="center">
                  <v-col cols="auto">
                    <v-avatar tile size="80">
                      <img src="/images/hunde/husky.png" />
                    </v-avatar>
                  </v-col>
                  <v-col>
                    <h3>Husky</h3>
                    <div>von 22,92 € bis 70,51 € mtl</div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="py-1" style="background-color:#67c1b9">
                <a
                  href="/vergleich/3d4057a91fb9f13ad40ad81ce99984b4b3c414d6c2908f7a88987d160ad0be64"
                  class="text-white"
                >
                  Tarifvergleich anzeigen
                </a>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>

     
      </v-col>
    </v-row>

       
  </div>
</template>

<script setup>
import { ref,onMounted,getCurrentInstance } from "vue";
import { useCommonDataStore } from "@/stores/common";
import BaseCalls from "@/services/BaseCalls";
import MenueDownload from "@/sites/base/MenueDownload.vue";
import { useBerechnungStore } from '@/stores/rechner';
const rechnerStore = useBerechnungStore();
const common = useCommonDataStore();
const { proxy } = getCurrentInstance();

const tarife = ref([]);
const stimmen = ref(false);
const berechnungen = ref(false);
const displayLimit = ref(15);
const showAll = ref(false);

async function getTarife() {
  let select = {
      action: "getTarife",
      type: "hunde",
      preise: "ja",
      number: "array",
        }
    const resp = await BaseCalls.postTarif(select); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.tarife) {
        tarife.value = resp.data.tarife;
      }

  }


async function getShowBerechnunge() {
  let select = {
      action: "getShowBerechnungen",
     
        }
    const resp = await BaseCalls.postRechner(select); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.showberechnungen) {
        berechnungen.value = resp.data.showberechnungen;
      }
  }

async function getStimmen() {
  let select = {
      action: "getStimmen",
     
        }
    const resp = await BaseCalls.postRechner(select); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.stimmen) {
        stimmen.value = resp.data.stimmen;
      }
  }

function getTimeAgo(ber_updated_date) {
  const now = new Date();
  const date = new Date(ber_updated_date);
  const diff = now - date; // difference in milliseconds

  const diffMinutes = Math.floor(diff / 60000);

  if (diffMinutes < 60) {
    if (diffMinutes == 0) {
      return `jetzt`;
    } else if (diffMinutes == 1) {
      return `vor einer Minute`;
    } else {
      return `vor ${diffMinutes} Minuten`;
    }
  } else if (diffMinutes < 1440) {
    const diffHours = Math.floor(diffMinutes / 60);
    if (diffHours == 1) {
      return `vor einer Stunde`;
    } else {
      return `vor ${diffHours} Stunden`;
    }
  } else {
    const diffDays = Math.floor(diffMinutes / 1440);
    if (diffDays == 1) {
      return `vor einem Tag`;
    } else {
      return `vor ${diffDays} Tagen`;
    }
  }
}

function showAllTarife() {
  showAll.value = !showAll.value;
}

onMounted(() => {
    getTarife();
    getStimmen();
    getShowBerechnunge();
    proxy.$updateDocumentHead(
      "Krankenversicherung Hund Vergleich - Die Testsieger " + common.getCurrentYear(),
      "Jetzt günstige Hundekrankenversicherungen ohne Selbstbeteiligung mit Leistungen für Physiotherapie und Homöopathie finden. Vergleich der besten Tierkrankenversicherungen Hund " + common.getCurrentYear() + "."
    );
  });

</script>

