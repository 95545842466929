<template>
  <div>
    <v-row>
      <v-col cols="12" md="4" xs="12">
        <v-select
          label="Tag"
          background-color="white"
          v-model="selDay"
          :items="Days"
        ></v-select>
      </v-col>
      <v-col cols="12" md="4" xs="12">
        <v-select
          label="Monat"
          background-color="white"
          v-model="selMonth"
          :items="Months"
          item-title="name"
          item-value="id"
          return-object
        ></v-select>
      </v-col>
      <v-col cols="12" md="4" xs="12">
        <v-select
          style="min-width: 100px"
          label="Jahr"
          background-color="white"
          v-model="selYear"
          :items="Years"
          item-title="name"
          item-value="id"
        ></v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue';

export default {
 
  props: {
    day: {
      type: Number,
      default: new Date().getDate(),
    },
    month: {
      type: Number,
      default: new Date().getMonth() + 1, // Anpassung auf 1-basierte Indexierung
    },
    year: {
      type: Number,
      default: new Date().getFullYear(),
    },
  },
  setup(props, { emit }) {
    const Months = [
      { id: 1, name: 'Januar' },
      { id: 2, name: 'Februar' },
      { id: 3, name: 'März' },
      { id: 4, name: 'April' },
      { id: 5, name: 'Mai' },
      { id: 6, name: 'Juni' },
      { id: 7, name: 'Juli' },
      { id: 8, name: 'August' },
      { id: 9, name: 'September' },
      { id: 10, name: 'Oktober' },
      { id: 11, name: 'November' },
      { id: 12, name: 'Dezember' },
    ];

    const Days = ref([]);
    const Years = ref([]);

    const selMonth = ref({});
    const selYear = ref(parseInt(props.year));
    const selDay = ref(parseInt(props.day));

    const initMonth = () => {
      const monthInit =
        typeof props.month === 'number' && !isNaN(props.month)
          ? props.month
          : new Date().getMonth() + 1;
      selMonth.value = Months.find((month) => month.id === monthInit);
    };

    const numDaysInMonth = (year, month) => {
      return new Date(year, month, 0).getDate();
    };

    const computeDays = () => {
      let list = [];
      if (selMonth.value && selMonth.value.id) {
        const num = numDaysInMonth(selYear.value, selMonth.value.id);
        for (let day = 1; day <= num; day++) {
          list.push(day);
        }
      }
      Days.value = list;
    };

    const computeYears = (start, end) => {
      let list = [];
      for (let y = start; y <= end; y++) {
        list.push({ id: y, name: y.toString() });
      }
      return list.reverse();
    };

    watch(
      () => props.year,
      (val) => {
        selYear.value = val;
      }
    );

    watch(
      () => props.month,
      () => {
        initMonth();
      }
    );

    watch(
      () => props.day,
      (val) => {
        selDay.value = val;
      }
    );

    watch(
      selMonth,
      (val) => {
        computeDays();
        if (Days.value.length < selDay.value) {
          selDay.value = Days.value.length;
        }
        if (val && val.id) {
          emit('update:month', val.id);
        }
      },
      { deep: true }
    );

    watch(selYear, (val) => {
      computeDays();
      emit('update:year', val);
    });

    watch(selDay, (val) => {
      emit('update:day', val);
    });

    onMounted(() => {
      const yearNow = new Date().getFullYear();
      Years.value = computeYears(yearNow - 29, yearNow);
      initMonth();
      computeDays();
    });

    return {
      Months,
      Days,
      Years,
      selMonth,
      selYear,
      selDay,
    };
  },
};
</script>

<style scoped></style>