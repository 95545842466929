<template>
  <div>
    <SchaeferHund v-if="route.params.rasse == 'Schaeferhund'" />
  </div>
</template>

<script setup>
import { onMounted, getCurrentInstance } from "vue";
import { useRoute } from "vue-router";
import SchaeferHund from "@/sites/rassen/SchaeferHund.vue";
const route = useRoute();
const { proxy } = getCurrentInstance();

onMounted(() => {
  let meta = {
    title: "",
    description: ""
  };

  if (route.params.rasse == "Schaeferhund") {
    meta = {
      title: "Tierkrankenversicherungen für Schäferhunde – Vom Welpen bis zum Senior",
      description: "Finden Sie die passende Tierkrankenversicherung für Ihren Schäferhund. Praktische Tipps zur Absicherung rassetypischer Erkrankungen bei Belgischen, Deutschen, Weißen und Schwarzen Schäferhunden – von Welpen bis Senioren.",
    };
  }
  
  proxy.$updateDocumentHead(
    meta.title,
    meta.description
  );
});
</script>
