// import { createRouter, createWebHashHistory } from 'vue-router'
import { createRouter, createWebHistory } from "vue-router";
import IndexHome from "../sites/IndexHome.vue";
import StartHund from "../components/rechner/StartHund.vue";
import StartKatze from "../components/rechner/StartKatze.vue";
import IndexHund from "../sites/IndexHund.vue";
import IndexKatze from "../sites/IndexKatze.vue";
import IndexVergleich from "../components/rechner/IndexVergleich.vue";
import IndexLogout from "../components/user/IndexLogout.vue";
import IndexAccount from "../components/user/IndexAccount.vue";
import RegisterUser from "../components/user/RegisterUser.vue";
import UpdateUser from "../components/user/UpdateUser.vue";
import SofortBestellung from "../components/user/SofortBestellung.vue";
import IndexKatzeVergleich from "../sites/IndexKatzeVergleich.vue";
import IndexGot from "../sites/IndexGot.vue";
import IndexImpressum from "../sites/IndexImpressum.vue";
import IndexUeber from "../sites/IndexUeber.vue";
import IndexUrlaub from "../sites/IndexUrlaub.vue";
import OpIndex from "../sites/OpIndex.vue";
import IndexZahnstein from "../sites/IndexZahnstein.vue";
const routes = [
  {
    path: "/",
    name: "home",
    component: IndexHome,
  },
  {
    path: "/rechner",
    name: "StartHund",
    component: StartHund,
  },
  {
    path: "/Op_Versicherung_Hund_Katze",
    name: "op_versicherung",
    component: OpIndex,
  },
  {
    path: "/zahnsteinentfernung_hund",
    name: "IndexZahnstein",
    component: IndexZahnstein,
  },
  {
    path: "/Urlaub_mit_Hund",
    name: "urlaub",
    component: IndexUrlaub,
  },
  {
    path: "/impressum",
    name: "impressum",
    component: IndexImpressum,
  },
  {
    path: "/ueber_die_eisbaumtabelle",
    name: "ueber",
    component: IndexUeber,
  },
  {
    path: "/Gebuehrenordnung_fuer_Tieraerzte",
    name: "infogot",
    component: IndexGot,
  },
  {
    path: "/katze",
    name: "IndexKatzeVergleich",
    component: IndexKatzeVergleich,
  },
  {
    path: "/vergleich_katze",
    name: "StartKatze",
    component: StartKatze,
  },
  {
    path: "/Krankenversicherung_Hund/:id",
    name: "hundeversicherung",
    component: IndexHund,
  
  },
  {
    path: "/Krankenversicherung_Katze/:id",
    name: "katzenversicherung",
    component: IndexKatze,
   
  },
// Rechner components
{
  path: "/nachbestellung/:token",
  name: "SofortBestellung",
  component: SofortBestellung,

},
{
  path: "/stammdaten",
  name: "stammdaten",
  component: UpdateUser,
},
  {
    path: "/vergleich/:token",
    name: "vergleich",
    component: IndexVergleich,
   
  },
  {
    path: "/tierarzt",
    name: "tierarzt",
    component: RegisterUser,
  
  },
  {
    path: "/account",
    name: "account",
    component: IndexAccount,
   
  },
  {
    path: "/logout",
    name: "logout",
    component: IndexLogout,
 
  },

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
