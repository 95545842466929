<template>
  <v-container>
  <div class="mt-4 ml-4">
   
    <v-row class="mb-4" style="margin-left: -20px;">
      <v-col cols="8">
      <v-row>
        <v-col cols="10">
    <h1>EisbaumTabelle | Schäferhunde <sup class="ggfonthigh">Neu</sup></h1>
    Die EisbaumTabelle  | <b>Schäferhunde</b> ist die erste unabhängige Vergleichstabelle speziell für  Hundekrankenversicherungen für Schäferhunde.
  </v-col>
  <v-col cols="2">
    <img
    :src="require('@/assets/schaeferhund.png')"
    style="width: 100px; float: left;"
  />
  </v-col>
</v-row>
  
  </v-col>
   <v-col cols="4">
<h2>Für Schäferhund-Züchter</h2>
Fordern Sie jetzt <b>kostenlos</b> EisbaumTabelle für Ihre Kunden an. 
<a href="mailto:info@eisbaumtable.de">
  <v-icon icon="mdi-email" class="mr-1"></v-icon>
  Schreiben Sie mir eine E-Mail
</a>

   </v-col>
   </v-row>
  <v-row>
    
      <v-col cols="8" >
        <v-btn color="#d84315" class="mb-4 nocaps" @click="getTarife(0)" style="margin-left:-10px;">Schäferhund Welpe</v-btn>
    <v-btn color="#d84315" class="ml-4 mb-4 nocaps" @click="getTarife(3)">Schäferhund 3 Jahr alt</v-btn>
    <v-btn color="#d84315" class="ml-4 mb-4 nocaps" @click="getTarife(7)">Schäferhund 7 Jahr alt</v-btn>
    
        <div style="border-right:4px solid #d84315;" class="pr-3">
        <v-row class="mb-2" style="background-color: #f2f2f2;">
          <v-col cols="3" style="border-bottom:4px solid #d84315;">
            <h3>Tarif</h3>
          </v-col>
          <v-col cols="1" style="border-bottom:2px solid #d84315;">
            <h3>Ø</h3>
            <span class="smalltext">Erstattung</span>
          </v-col>
          <v-col cols="4" style="border-bottom:2px solid #d84315;"><h3>für Schäferhunde</h3>
          
          <span class="smalltext">wichtige Leistungen</span>
          <ul class="smalltext">
            <li> Physiotherapie nach OP</li>
            <li> Physiotherapie</li>
            <li> Erstattung für angeborene Fehlentwicklungen</li>
            <li> Erstattung Hüftgelenksdysplasie</li>
          </ul>
        </v-col>
          <v-col cols="1" style="border-bottom:2px solid #d84315;"><h3>OP</h3>
            <span class="smalltext">Opera-tionen</span></v-col>
          <v-col cols="1" style="border-bottom:2px solid #d84315;"><h3>Beh.</h3>
            <span class="smalltext">Behand-lungen</span></v-col>
          <v-col cols="2" style="border-bottom:2px solid #d84315;"><h3>Preis</h3>
            <span class="smalltext">Schäferhund</span>
          </v-col>
        </v-row>

        <div v-for="(tarif, index) in tarife" :key="tarif.tier_id">
          <v-row 
            class="mb-2" 
            v-if="tarif.preis > 0" 
            :style="index % 2 === 1 ? 'background-color: #f2f2f2;' : 'border-bottom:2px solid #f2f2f2;'"
          >
            <v-col cols="3">
              <b><router-link :to="`/Krankenversicherung_Hund/${tarif.tier_url}`">
                {{ tarif.tier_title }}
              </router-link></b>
            </v-col>
            <v-col cols="1">
  <b><a @click="rechnerStore.setShowPopTarif(tarif, 'rechenweg')">{{ tarif.tier_wert }} %</a></b>
            </v-col>
            <v-col cols="4">
            <ul>
              <li  v-if="hasValidContent(tarif.tier_op_physio)">
                <b class="text-green">Ja, Physio nach OP</b> 
                <a @click="showDetailText(tarif.tier_op_physio, tarif.tier_title, 'Erstattung Physiotherapie nach OP')">
                  Info 
                </a>
              </li>
              <li v-if="hasValidContent(tarif.tier_behandlung_physio)">
                <b class="text-green">Ja, Physio</b> 
                <a @click="showDetailText(tarif.tier_behandlung_physio, tarif.tier_title, 'Erstattung Physiotherapie')">
                  Info
                </a>
              </li>
              <li v-if="hasValidContent(tarif.tier_behandlung_fehlerangeboren)">
                <b class="text-green">Ja, angeb. Fehlentw.</b> 
                <a @click="showDetailText(tarif.tier_behandlung_fehlerangeboren, tarif.tier_title, 'Erstattung für angeborene Fehlentwicklungen')">
                  Info
                </a>
              </li>
              <li v-if="hasValidContent(tarif.tier_leistung_huefteuelle)">
                <b class="text-green">Ja, Hüftgelenksdys.</b> 
                <a @click="showDetailText(tarif.tier_leistung_huefteuelle, tarif.tier_title, 'Erstattung Hüftgelenksdysplasie')">
                  Info
                </a>
              </li>
            </ul>
            </v-col>
         <v-col cols="1"><v-icon class="text-green" >mdi-check</v-icon></v-col>
         <v-col cols="1"><v-icon class="text-green" >mdi-check</v-icon></v-col>
            <v-col cols="2">
              <b>{{ tarif.preis }} €</b>
            </v-col>
          </v-row>
        </div>

    
      </div>
      <br><br>

      <div style="border-right:4px solid #d87315;" class="pr-3 mt-12">
        
        <div class="mb-4" style="margin-left: -10px;">
        <h1 >Eisbaum's "LieblingsTabelle" | <span class="redtext">Schäferhund</span></h1>
        Diese Schäferhund Krankenversicherungen wurden gerade von Schäferhund-Haltern abgeschlossen.
      </div>
        <v-row class="mb-2 mt-4" style="background-color: #f2f2f2;">
          <v-col cols="3" style="border-bottom:4px solid #d87315;">
            <h3>Schäferhund</h3>
          </v-col>
         
          <v-col cols="4" style="border-bottom:2px solid #d87315;"><h3>abgeschlossene Versicherung</h3></v-col>
          <v-col cols="5" style="border-bottom:2px solid #d87315;"><h3>günstige Alternative</h3></v-col>
        </v-row>
        <div v-for="(rasse,index) in rasseberechnungen" :key="rasse.ber_id">
          <v-row  :style="index % 2 === 1 ? 'background-color: #f2f2f2;' : 'border-bottom:2px solid #f2f2f2;'" class="mb-2" style="background-color: #f2f2f2;">
            <v-col cols="3">
              <b>{{ rasse.ber_name }}</b>
              <br>
              <span class="smalltext">{{ rasse.ber_alter }} Jahre, <span v-if="rasse.ber_geschlecht == 1">Rüde</span><span v-else>Hündin</span></span>
            </v-col>
          
            <v-col cols="4">
            {{ rasse.ber_emp_1_name }}
            </v-col>
            <v-col cols="5">
           {{ rasse.ber_billig_name }}
            </v-col>
          </v-row>
        </div>
        </div>


<div class="flex-grow-1 mt-12" >
  <h2 class="mb-3">Diese 3 Kriterien sind für mich entscheidend bei der Wahl der richtigen Tierkrankenversicherung für meinen Schäferhund:</h2>
  

  
  <div id="criterion-1" class="criterion mb-4">
    <h4 class="font-weight-bold">1. Leistet die Tierkrankenversicherung für rassespezifische Erkrankungen?</h4>
    <p>Schäferhunde neigen, wie viele andere reinrassige Hunde, zu rassespezifischen Erkrankungen, wie zum Beispiel Hüftdysplasie (HD) oder Ellbogendysplasie (ED). Deshalb ist es für mich wichtig, dass die Tierkrankenversicherung die Behandlung dieser Krankheiten nicht von vornherein ausschließt.</p>
    
    <div class="pa-3 my-2" style="background-color: #f5f5f5; border-left: 4px solid #d84315;">
      <h5 class="font-weight-bold">Diese Tierkrankenversicherungen bieten Leistungen für rassespezifische Erkrankungen:</h5>
      <p>Nur einige Anbieter übernehmen die Kosten für rassespezifische Erkrankungen, wenn diese vor Versicherungsabschluss nicht bekannt waren:</p>
      <ul class="pl-4">
        <li><span class="font-weight-bold">Barmenia:</span> Leistung mit einer Wartezeit von 18 Monaten (im Premium Plus-Tarif nur 6 Monate).</li>
        <li><span class="font-weight-bold">Helvetia:</span> Leistung mit einer Wartezeit von 6 Monaten.</li>
        <li><span class="font-weight-bold">HanseMerkur:</span> Deckt Fehlentwicklungen, die vor Antragstellung nicht bekannt waren, bis zu 5.000 EUR während der gesamten Vertragslaufzeit (Wartezeit: 12 Monate).</li>
        <li><span class="font-weight-bold">Interlloyd:</span> Leistet einmalig 300 EUR.</li>
      </ul>
    </div>
  </div>
  
  <div id="criterion-2" class="criterion mb-4">
    <h4 class="font-weight-bold">2. Leistet die Tierkrankenversicherung für Physiotherapie?</h4>
    <p>Für mich ist es essenziell, dass die Tierkrankenversicherung auch Physiotherapie abdeckt. Mein Schäferhund ist durch seine Größe und seinen Bewegungsdrang anfällig für Muskelverletzungen, Zerrungen oder Gelenkprobleme. Zudem zählt Hüftdysplasie zu den häufigsten Erkrankungen dieser Rasse, und Physiotherapie kann helfen, die Auswirkungen zu lindern. Auch bei Degenerativer Myelopathie, einer Rückenmarkserkrankung, kann regelmäßige Physiotherapie den Krankheitsverlauf verlangsamen.</p>
    
    <div class="pa-3 my-2" style="background-color: #f5f5f5; border-left: 4px solid #d84315;">
      <h5 class="font-weight-bold">Diese Tierkrankenversicherungen bieten Leistungen für Physiotherapie:</h5>
      <ul class="pl-4">
        <li><span class="font-weight-bold">Helvetia:</span> Erstattet nur nach einer OP und mit Begrenzung.</li>
        <li><span class="font-weight-bold">Barmenia:</span> Deckt maximal 10 Sitzungen à 30 Minuten pro Indikation, auch ohne vorherige OP.</li>
        <li><span class="font-weight-bold">HanseMerkur:</span> Leistet maximal 250 EUR pro Jahr, aber nur bis zum 28. Kalendertag nach einer Operation.</li>
        <li><span class="font-weight-bold">Interlloyd:</span> Erstattet nur nach einer OP, mit 400 EUR (Protect Tarif) bzw. 800 EUR (Premium Tarif) je Versicherungsfall und Versicherungsjahr.</li>
      </ul>
    </div>
  </div>
  
  <div id="criterion-3" class="criterion mb-4">
    <h4 class="font-weight-bold">3. Wie hoch sind die Begrenzungen für chirurgische Eingriffe?</h4>
    <p>Ich möchte sicherstellen, dass die Versicherung umfassende Leistungen für diagnostische Maßnahmen und operative Eingriffe abdeckt. Sollte mein Schäferhund beispielsweise eine schwere Hüftgelenksdysplasie haben, kann eine Operation notwendig werden. Auch das Risiko einer Magendrehung ist bei dieser Rasse erhöht, sodass eine sofortige OP überlebenswichtig sein kann.</p>
    
    <div class="pa-3 my-2" style="background-color: #f5f5f5; border-left: 4px solid #d84315;">
      <h5 class="font-weight-bold">Diese Tierkrankenversicherungen leisten für chirurgische Eingriffe:</h5>
      <p>Alle Versicherungen bieten Leistungen für Operationen an. Wichtig ist für mich jedoch, dass die jährlichen Begrenzungen ausreichend hoch sind.</p>
      <ul class="pl-4">
        <li><span class="font-weight-bold">Barmenia:</span> Unbegrenzte Leistungen für Operationen.</li>
        <li><span class="font-weight-bold">Helvetia:</span> Begrenzung auf 2.500 EUR (PetCare Basis) bzw. 5.000 EUR (PetCare Kompakt) pro Jahr.</li>
        <li><span class="font-weight-bold">HanseMerkur:</span> Unbegrenzte Leistungen (Premium Plus) oder Begrenzung auf 5.000 EUR pro Jahr (Premium).</li>
        <li><span class="font-weight-bold">Interlloyd:</span> Unbegrenzte Leistungen (Interlloyd Tierkranken Premium) oder Begrenzung für Behandlungen und OPs auf 10.000 EUR pro Jahr (Interlloyd Tierkranken Protect).</li>
      </ul>
    </div>
  </div>
  
  <div class="conclusion pa-3" style="background-color: #f9f9f9; border: 1px solid #d84315; border-radius: 4px;">
    <h4 class="font-weight-bold">Mein Fazit:</h4>
    <p>Nach diesem Auswahlprozess bleiben nur wenige Tarife übrig, die umfassende Leistungen für rassespezifische Erkrankungen, Physiotherapie und chirurgische Eingriffe bieten. Die richtige Wahl hängt davon ab, welche Aspekte mir am wichtigsten sind – sei es eine möglichst geringe Wartezeit, hohe Erstattungsbeträge oder unbegrenzte Leistungen für Operationen.</p>
  </div>
</div>



<div class="my-8" >
    
          <div class="mb-4">
            <h2>Erstattungsbeispiel Zahnextraktion | Das erstattet die Tierkrankenversicherung für Schäferhund Tim</h2>
            <p>Zahnmedizinischer Eingriff unter Vollnarkose, inklusive Nachsorge<br>
            Tim, Deutscher Schäferhund, 5 Jahre<br>
            Tierkrankenversicherung: Barmenia Hunde Premium-Krankenschutz</p>
            
            <v-table density="compact" class="mt-4" style="border-right:4px solid #d87315;">
              <thead>
                <tr>
                  <th>Anzahl</th>
                  <th>Beschreibung</th>
                  <th>GOT Position</th>
                  <th>Kosten lt. Rechnung</th>
                  <th>Erstattung</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td>Allgemeine Untersuchung mit Beratung, Hund</td>
                  <td>16</td>
                  <td>29,51€</td>
                  <td>29,51€</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Eingehende klinische Untersuchung einzelner Organe des Herz-Kreislauf-Systems, Hund</td>
                  <td>643</td>
                  <td>19,22€</td>
                  <td>19,22€</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Venenkatheter peripher einlegen</td>
                  <td>225</td>
                  <td>18,27€</td>
                  <td>18,27€</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Venenkatheter entfernen</td>
                  <td>226</td>
                  <td>8,27€</td>
                  <td>8,27€</td>
                </tr>
                <tr>
                  <td>1 Stück</td>
                  <td>Verbrauchsmaterial Venenzugang (Katheter, Tupfer, Pflaster, Verband)</td>
                  <td></td>
                  <td>5,95€</td>
                  <td>5,95€</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Intravenöse Kombinationsnarkose, Hund</td>
                  <td>330</td>
                  <td>39,27€</td>
                  <td>39,27€</td>
                </tr>
                <tr>
                  <td>0.55 ml</td>
                  <td>Torphadine 10 mg / ml Injektionslösung</td>
                  <td></td>
                  <td>7,68€</td>
                  <td>7,68€</td>
                </tr>
                <tr>
                  <td>2 ml</td>
                  <td>Ziapam 5 mg / ml Injektionslösung</td>
                  <td></td>
                  <td>3,75€</td>
                  <td>3,75€</td>
                </tr>
                <tr>
                  <td>6 ml</td>
                  <td>PropoVet Multidose 10 mg / ml Injektionsemulsion</td>
                  <td></td>
                  <td>5,95€</td>
                  <td>5,95€</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Endotracheale Intubationsnarkose mit Spontanatmung</td>
                  <td>345</td>
                  <td>91,87€</td>
                  <td>91,87€</td>
                </tr>
                <tr>
                  <td>1 Stück</td>
                  <td>Verbrauchsmaterial Endotrachealtubus, Sauerstoff, Narkosegas, Atemkalk, Schläuche</td>
                  <td></td>
                  <td>23,80€</td>
                  <td>23,80€</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Infusion per Infusomat</td>
                  <td>235</td>
                  <td>93,05€</td>
                  <td>93,05€</td>
                </tr>
                <tr>
                  <td>1 Stück</td>
                  <td>Verbrauchsmaterial Infusion (Infusionslösung, Infusionsschläuche, Verlängerung)</td>
                  <td></td>
                  <td>5,95€</td>
                  <td>5,95€</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Narkosemonitoring mit mehr als zwei Parametern</td>
                  <td>350</td>
                  <td>73,96€</td>
                  <td>73,96€</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Narkoseprotokoll, ausführlich, je angefangene 15 Minuten</td>
                  <td>282</td>
                  <td>71,97€</td>
                  <td>71,97€</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Dentalröntgen (erste und zweite Aufnahme, jeweils)</td>
                  <td>166</td>
                  <td>33,20€</td>
                  <td>33,20€</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Infiltrationsanästhesie</td>
                  <td>284</td>
                  <td>15,41€</td>
                  <td>15,41€</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Zahnextraktion, Hund</td>
                  <td>951</td>
                  <td>12,79€</td>
                  <td>12,79€</td>
                </tr>
                <tr>
                  <td>1 Stück</td>
                  <td>Verbrauchsmaterial Zahnbehandlung: Politur, Tupfer, Wasser</td>
                  <td></td>
                  <td>17,85€</td>
                  <td>17,85€</td>
                </tr>
                <tr>
                  <td>1 Stück</td>
                  <td>Monosyn 4-0 Chirurgischer Faden</td>
                  <td></td>
                  <td>13,74€</td>
                  <td>13,74€</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Injektion subkutan, intrakutan oder intramuskulär, Hund</td>
                  <td>221</td>
                  <td>14,40€</td>
                  <td>14,40€</td>
                </tr>
                <tr>
                  <td>2.2 ml</td>
                  <td>Carprox vet 50 mg / ml Injektionslösung</td>
                  <td></td>
                  <td>8,39€</td>
                  <td>8,39€</td>
                </tr>
                <tr>
                  <td>1 Stück</td>
                  <td>Verbrauchsmaterial Station nach Operation (Decken, Wärme, Reinigung)</td>
                  <td></td>
                  <td>5,95€</td>
                  <td>5,95€</td>
                </tr>
                <tr class="font-weight-bold" style="background-color: #f5f5f5;">
                  <td></td>
                  <td>Summe</td>
                  <td></td>
                  <td>620,20€</td>
                  <td>620,20€</td>
                </tr>
              </tbody>
            </v-table>
            
            <p class="mt-3 font-weight-bold">Die Barmenia Tierkrankenversicherung Premium erstattet 100% für den Zahnmedizinischen Eingriff bei Schäferhund Tim.</p>
          </div>
    
</div>


      </v-col>
      <v-col cols="4" class="pl-4">
  <v-row class="mb-4">
    <v-col cols="12">
      <div class="mb-4">
      <h2 class="mr-3 mb-0">Tobi`sTop Ten <em class="ggfonthigh">Schäferhunde</em></h2>
     Nach diesen Kritieren habe ich die besten Schäferhund-Krankenversicherungen gelistet. 
     </div>
     <div class="mb-4">
      <ol class="mt-4 ml-4">
      <li><a href="#criterion-1">Leistet die Tierkrankenversicherung für rassespezifische Erkrankungen?</a></li>
      <li><a href="#criterion-2">Leistet die Tierkrankenversicherung für Physiotherapie?</a></li>
      <li><a href="#criterion-3">Wie hoch sind die Begrenzungen für Chirurgische Eingriffe?</a></li>
      
      </ol>
    </div>
    </v-col>
  </v-row>
  <div class="mb-8">
    <iframe 
      width="100%" 
      height="315" 
      src="https://www.youtube.com/embed/LxvhKz_TktI" 
      title="EisbaumTabelle | 3 Auswahlkriterien für die richtige Schäferhund-Krankenversicherung" 
      frameborder="0" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
      referrerpolicy="strict-origin-when-cross-origin" 
      allowfullscreen
      class="video-container"
    ></iframe>
    </div>

    <div class="mb-8">
      <v-card elevation="1">
        <v-card-text>
<div class="mb-4">
  <img
    :src="require('@/assets/weisserSchaeferhund.png')"
    style="width: 320px; float: left;  margin-bottom: 20px;"
  />
</div>
<div class="mt-4">
        <h3>Veränderung in der Gebührenordnung für Tierärzte (GOT) 2023</h3>
        <em>"Wie soll ich mir den nächsten Tierarztbesuch leisten?"</em><br>
Massiv gestiegene Lebenshaltungskosten und die neue Gebührenordnung für Tierärzte führen dazu, dass viele Hundebesitzer in Deutschland nicht mehr wissen, wie sie sich den nächsten Tierarztbesuch leisten sollen. Eine Tierkrankenversicherung schafft hier
 Abhilfe und wird deshalb von immer mehr Tierärzten empfohlen,
  "Doch Vorsicht, es gibt auch schwarze Schafe in der Branche", 
  sagt Tobias Zorn Herausgeber der EisbaumTabelle, die unabhängige Vergleichstabelle
   für Hundekrankenversicherungen. Deshalb vergleicht EisbaumTabelle Hundekrankenversicherungen anhand einer realen 5-jährigen Behandiungshistorie und orientiert sich zudem an den Leistungsvorgaben der
    Bundestierärztekammer (BTK). Zu hohe Leistungsversprechen und Leistungsbegrenzungen werden so sichtbar und können bei der Auswahl einer Hunde-Krankenversicherung von Tierhaltern berücksichtigt werden.
   
    <br>
    Quelle: Offizielles Magazin für Mitglieder und Freunde des Bundesverein für Weiße Schweizer Schäferhunde e.V. (BVWS), Ausgabe 02/2023 (Seite 11)
  </div>
  </v-card-text>
  </v-card>
  </div>   
  </v-col>
    </v-row>
  </div>
  <v-dialog v-model="dialogVisible" max-width="500px">
    <v-card>
      <v-card-title class="headline">{{ dialogTitle }}</v-card-title>
      <v-card-subtitle>{{ dialogSubtitle }}</v-card-subtitle>
      <v-card-text>{{ dialogText }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialogVisible = false">Schließen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </v-container>

  <!-- Add this dialog component at the end of your template, before the closing </v-container> tag -->
 
</template>

<script setup>
import { onMounted,ref } from "vue";
import BaseCalls from "@/services/BaseCalls";
import { useLoaderStore } from "@/stores/loader";


import { useBerechnungStore } from '@/stores/rechner';
const rechnerStore = useBerechnungStore();

const loaderStore = useLoaderStore();
const tarife = ref([]);
const rasseberechnungen = ref([]);

// Add these refs for the dialog
const dialogVisible = ref(false);
const dialogText = ref('');
const dialogTitle = ref('');
const dialogSubtitle = ref('');

// Function to check if content is valid (has text and doesn't contain "Kein")
function hasValidContent(text) {
  if (!text) return false;
  return typeof text === 'string' && text.trim() !== '' && !text.toLowerCase().includes('kein');
}

// Update the showDetailText function to use the dialog with title and subtitle
function showDetailText(text, title, subtitle) {
  if (text && text.trim() !== '') {
    dialogText.value = text;
    dialogTitle.value = title || 'Details';
    dialogSubtitle.value = subtitle || '';
    dialogVisible.value = true;
  }
}

async function getTarife(alter) {
  loaderStore.setLoader(true);
  loaderStore.setLoader(false);
  let select = {
      action:  "getRassenTarife" ,
      alter: alter,
    }
   
    const resp = await BaseCalls.postRechner(select); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.tarife) {
        tarife.value = resp.data.tarife;
      }
     
  }

  async function getRasseBerechnungen(rasse_id) {
    
    let select = {
      action: "getRasseBerechnungen",
      rasse_id: rasse_id,
    }
    const resp = await BaseCalls.postRechner(select);
    if (resp.data && resp.data.rasseberechnungen) {
      rasseberechnungen.value = resp.data.rasseberechnungen;
    }
   
  }

onMounted(() => {
  getTarife(0);
  getRasseBerechnungen(356);
});

</script>
