<template>
  <div>
      <IndexPreisEst v-if="type == 'preis_est'" :tarif="tarif" />
      <IndexRechenweg tierart="1" v-if="type == 'rechenweg'" :tarif="tarif" />
      <IndexRechenweg tierart="2" v-if="type == 'rechenwegkatze'" :tarif="tarif" />
  </div>
</template>

<script setup>
import { defineProps } from "vue";
  import IndexRechenweg from "./IndexRechenweg";
  import IndexPreisEst from "./IndexPreisEst";

  const props = defineProps({
  tarif: {
    type: Object,
    required: true,
  },
  type: {
    type: String,
  },
});
  console.log(props.tarif);
</script>
