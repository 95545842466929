<template>
  <div>
    <v-row v-if="userStore.user" class="mt-12">
      <v-col cols="12" md="8">
        <!-- Navigation Buttons -->
        <v-row  left>
          <v-col cols="12">
          <v-btn
           rounded="1"
          tile
            class="nocaps mr-4"
            @click="activeSection = 'account'"
            :color="activeSection === 'account' ? '#d84315' : 'grey'"
            
          >
            Account Daten
          </v-btn>
          <v-btn
          rounded="1"
            tile
            class="nocaps"
            @click="activeSection = 'password'"
            :color="activeSection === 'password' ? '#d84315' : 'grey'"
            
          >
            Passwort ändern
          </v-btn>
          </v-col>
        </v-row>

        <!-- Account Data Section -->
        <div v-if="activeSection === 'account'">
          <br />
          <span v-if="updateErfolg">
            <h3 class="text-green">Wir haben Ihre Daten geändert</h3>
          </span>

          <v-form ref="updateForm" @submit.prevent="submitUserUpdate">
            <h3>Hier können Sie Ihre Kontaktdaten bearbeiten</h3>
            <span class="caption">
              Wenn Sie Ihre E-Mail ändern wollen, schicken Sie uns bitte eine
              E-Mail mit Ihrer User-ID: {{ userdata.user_id }} an:
              info@waizmanntabelle.de.
            </span>
            <v-row class="mt-4">
              <v-col cols="12">
                <v-text-field
                  v-model="userdata.user_name"
                  background-color="white"
                  type="text"
                  label="Name"
                  :rules="[checkTextField('user_name')]"
                />
              </v-col>
            </v-row>
            <v-row class="mt-4">
              <v-col cols="12">
                <v-text-field
                  v-model="userdata.user_url"
                  background-color="white"
                  type="text"
                  label="Ihre Webseite"
                />
              </v-col>
            </v-row>

            <v-row class="mt-4">
              <v-col cols="8">
                <v-text-field
                  v-model="userdata.user_strasse"
                  background-color="white"
                  type="text"
                  label="Ihre Straße"
                  :rules="[checkTextField('user_strasse')]"
                />
              </v-col>
              <v-col cols="3" class="ml-4">
                <v-text-field
                  v-model="userdata.user_hsnr"
                  background-color="white"
                  type="text"
                  label="Hausnummer"
                />
              </v-col>
            </v-row>

            <v-row class="mt-4">
              <v-col cols="3">
                <v-text-field
                  v-model="userdata.user_plz"
                  background-color="white"
                  type="text"
                  label="PLZ"
                  :rules="[checkTextField('user_plz')]"
                />
              </v-col>
              <v-col cols="8" class="ml-2">
                <v-text-field
                  v-model="userdata.user_ort"
                  background-color="white"
                  type="text"
                  label="Ort"
                  :rules="[checkTextField('user_ort')]"
                />
              </v-col>
            </v-row>

            <v-row class="mt-2" justify="start">
              <v-col cols="12">
                <v-btn
                  @click="submitUserUpdate"
                  tile
                  color="#d84315"
                  class="nocaps"
                  style="color: white"
                >
                  Jetzt Änderungen speichern
                </v-btn>
              </v-col>
            </v-row>

            <v-row v-if="error_step2" class="mt-2">
              <v-col cols="1"></v-col>
              <v-col cols="11">
                <div class="pa-2"
                  style="background-color: #d84315;">
                  <h3 class="subtitle text-white">
                    Achtung bei der Übertragung kam es zu einem Fehler
                  </h3>
                  <span class="text-white">{{ error_step2_text }}</span> 
                </div>
              </v-col>
            </v-row>
          </v-form>
        </div>

        <!-- Password Change Section -->
        <div v-else-if="activeSection === 'password'">
          <br />
          <span v-if="updatePwdErfolg">
            <h3 class="text-green">Wir haben Ihr Passwort geändert</h3>
          </span>

          <v-form
            ref="updatePassForm"
            v-if="!updatePwdErfolg"
            @submit.prevent="submitUserPwdUpdate"
          >
            <h3 class="title">Hier können Sie Ihr Passwort ändern</h3>
            <span class="caption">
              Wenn Sie Ihre E-Mail ändern wollen, schicken Sie uns bitte eine
              E-Mail mit Ihrer User-ID: {{ userdata.user_id }} an:
              info@waizmanntabelle.de.
            </span>

            <v-row class="mt-4">
              <v-col cols="12">
                <v-text-field
                  v-model="userdata.user_pass_neu1"
                  background-color="white"
                  :append-icon="showPwd1 ? 'visibility' : 'visibility_off'"
                  :rules="[checkTextField('user_pass_neu1')]"
                  :type="showPwd1 ? 'password' : 'text'"
                  label="Ihr neues Passwort"
                  autocomplete="on"
                  @click:append="showPwd1 = !showPwd1"
                />
              </v-col>
            </v-row>

            <v-row class="mt-4">
              <v-col cols="12">
                <v-text-field
                  v-model="userdata.user_pass_neu2"
                  background-color="white"
                  :append-icon="showPwd2 ? 'visibility' : 'visibility_off'"
                  :rules="[checkTextField('user_pass_neu2')]"
                  :type="showPwd2 ? 'password' : 'text'"
                  label="Wiederholen Sie Ihr neues Passwort"
                  autocomplete="on"
                  @click:append="showPwd2 = !showPwd2"
                />
              </v-col>
            </v-row>

            <v-row class="mt-2" justify="start">
              <v-col cols="12">
                <v-btn
                  @click="submitUserPwdUpdate"
                  tile
                  color="#d84315"
                  class="nocaps"
                  style="color: white"
                >
                  Jetzt neues Passwort speichern
                </v-btn>
              </v-col>
            </v-row>

            <v-row v-if="error_step2" class="mt-2">
              <v-col cols="12">
                <div class="pa-2"
                  style="background-color: #d84315;">
                  <h3 class="subtitle text-white">
                    Achtung bei der Übertragung kam es zu einem Fehler
                  </h3>
                  <span class="text-white">{{ error_step2_text }}</span>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance, watch } from "vue";
import BaseCalls from "@/services/BaseCalls";
import { useUserStore } from "@/stores/user";
import { useLoaderStore } from "@/stores/loader";
const { proxy } = getCurrentInstance();
const userStore = useUserStore();
const loaderStore = useLoaderStore();

function setLoading(loading) {
  loaderStore.setLoader(loading);
}

const activeSection = ref("account"); // Track the active section

const userdata = ref({
  action: "updateuser", // Action is set immediately
  user_pass_neu1: "",
  user_pass_neu2: "",
});

// Watch for changes in userStore.user and update userdata accordingly
watch(
  () => userStore.user,
  (newUser) => {
    if (newUser && newUser.user_id) {
      // Update userdata with the new user data
      userdata.value = {
        ...userdata.value, // Preserve existing properties like 'action'
        user_id: newUser.user_id,
        user_email: newUser.user_email,
        user_name: newUser.user_name,
        user_url: newUser.user_url,
        user_vorname: newUser.user_vorname,
        user_strasse: newUser.user_strasse,
        user_plz: newUser.user_plz,
        user_ort: newUser.user_ort,
      };
    }
  },
  { immediate: true } // Run the watcher immediately in case user data is already available
);

const showPwd1 = ref(false);
const showPwd2 = ref(false);

const error_step2 = ref(false);
const error_step2_text = ref("");
const error_step1 = ref(false);
const error_step1_text = ref("");
const updateErfolg = ref(false);
const updatePwdErfolg = ref(false);

function checkTextField(field) {
  console.log(field);
  return [];
}

function checkFormPass() {
  let checkPassed = true;
  if (userdata.value.user_pass_neu1 !== userdata.value.user_pass_neu2) {
    error_step2.value = true;
    error_step2_text.value = "Ihre beiden Passwörter stimmen nicht überein.";

    return false;
  } else if (
    !userdata.value.user_pass_neu1 ||
    !userdata.value.user_pass_neu2
  ) {
    error_step2.value = true;
    error_step2_text.value =
      "Bitte geben Sie Ihr Passwort an (und auch die Passwort-Wiederholung).";
    return false;
  } else {
    return checkPassed;
  }
}

async function submitUserPwdUpdate() {
  if (!checkFormPass()) {
    return true;
  }
  setLoading(true);
  error_step2_text.value = "";
  error_step2.value = false;
  userdata.value.action = "updateuserpasswort";
  const resp = await BaseCalls.postUser(userdata.value);
  if (resp.data.error_step2) {
    setLoading(true);
    error_step2_text.value = resp.data.error_step2_text;
    error_step2.value = resp.data.error_step2;
  } else {
    setLoading(false);
    if (resp.data.erfolg) {
      updatePwdErfolg.value = true;
      userdata.value.user_pass_neu1 = "";
      userdata.value.user_pass_neu2 = "";
      userdata.value = resp.data.user;
    } else {
      error_step1_text.value =
        "Fehler bitte versuchen Sie es noch einmal.";
      error_step1.value = true;
    }
  }
  setLoading(false);
}

function checkForm() {
  let checkPassed = true;
  return checkPassed;
}

async function submitUserUpdate() {
  if (!checkForm()) {
    return true;
  }
  setLoading(true);
  error_step2_text.value = "";
  error_step2.value = false;
  const resp = await BaseCalls.postUser(userdata.value);
  if (resp.data.error_step2) {
    setLoading(true);
    error_step2_text.value = resp.data.error_step2_text;
    error_step2.value = resp.data.error_step2;
  } else {
    setLoading(false);
    if (resp.data.erfolg) {
      updateErfolg.value = true;
      userdata.value.user_pass_neu1 = "";
      userdata.value.user_pass_neu2 = "";
      userdata.value = resp.data.user;
    } else {
      error_step1_text.value =
        "Fehler bitte versuchen Sie es noch einmal.";
      error_step1.value = true;
    }
  }
  setLoading(false);
}

onMounted(() => {
  proxy.$updateDocumentHead(
    "Hier können Sie Ihre Stammdaten ändern",
    "Hier können Sie Ihre Stammdaten ändern"
  );
});
</script>