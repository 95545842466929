<template>
  <div>
    <v-card outlined>
      <v-card-text>
        <span v-if="showType == 'hund'">
          <h2>Vergleich starten</h2>
          <p>Vergleichen Sie jetzt die besten Hunde-Krankenversicherungen.</p>
          <v-btn
            href="/rechner"
            class="nocaps mb-4 text-white"
            style="background-color: #d84315; color: #fff"
            >jetzt Vergleich starten</v-btn
          >
        </span>
        <span v-if="showType == 'katze'">
          <h2>Vergleich starten</h2>
          <p>Vergleichen Sie jetzt die besten Katzen-Krankenversicherungen.</p>
          <v-btn
            href="/vergleich_katze"
            class="nocaps mb-4 text-black"
            style="background-color: #fecf0c; color: #fff"
            >jetzt Vergleich starten</v-btn
          >
        </span>
      </v-card-text>
    </v-card>
  </div>
</template>

<script setup>
import { defineProps} from "vue"

const props = defineProps({
  showType: {
    type: String,
    required: true,
  },
});
console.log(props.showType);
</script>