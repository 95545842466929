<template>
  <div class="mt-4 ggfont greytext">
    Katzen-Krankenversicherung im Test <br />
    <h2>
      <span class="redtext">{{ tarif.tier_title }}</span>
    </h2>
    <v-row v-if="tarif.tier_vergleich_nein > 0" class="my-4">
      <v-col cols="12" md="12">
        <v-alert
          border="top"
          colored-border
          type="info"
          color="red"
          elevation="2"
        >
          <h2 v-if="tarif.tier_vergleich_status == 1">
            Der Tarif wird gerade analysiert
          </h2>
          <h2 v-if="tarif.tier_vergleich_status == 2">
            Versicherung arbeitet nicht mit Vergleichsportalen
          </h2>
          <h2 v-if="tarif.tier_vergleich_status == 3">
            Tarif wurde von der Versicherung geschlossen
          </h2>

          Aus diesem Grund wurden die Tarifinformationen bisher noch nicht von
          der Versicherung bestätigt bzw. Fragen zum Tarif von der Versicherung
          beantwortet. Die Tarifinformationen basieren lediglich auf unserer
          Auswertung der zugänglichen Tarifunterlagen. Der Tarif wird nicht in
          unserem Online Rechner berücksichtigt, weil wir keine Verantwortung
          für die Vollständigkeit, Richtigkeit oder Aktualität der Informationen
          übernehmen können.
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        Die Katzen-Krankenversicherung {{ tarif.tier_title }} leistet im
        Durchschnitt <b class="redtext">{{ tarif.tier_wert }}%</b>. Diese
        Durchschnittserstattung wurde mit Hilfe der Eisbaum Realwert-Methode
        ermittelt.<br />
      </v-col>
    </v-row>

    <br />

    <v-btn
      v-if="tarif.un_abschluss_url != ''"
      class="nocaps my-4"
      style="background-color: #d84315; color: #fff"
    >
      <br />
      <a
        @click="saveAntragClickend()"
        :href="tarif.un_abschluss_url"
        target="_blank"
        style="color: #fff"
        >jetzt {{ tarif.un_name }} online beantragen
      </a>
    </v-btn>

    <div class="divider" />
    <h3>Leistungsbeschreibung:</h3>
    {{ tarif.tier_title }}<br /><br />
    <v-row v-if="tarif.tier_sb_art == 3">
      <v-col cols="12" md="12">
        <b>Dieser Tarif hat eine Selbstbeteiligung:</b><br />
        Die Selbstbeteiligung beträgt {{ tarif.tier_sb_prozent }} % des
        Rechnungsbetrages, maximal {{ tarif.tier_sb_euro }} € für alle
        zeitgleich zusammen eingereichten Rechnungen eines Abrechnungsjahres.
        Sparen Sie Geld, indem Sie die Rechnungen für Behandlungen, die in einem
        Abrechnungsjahr durchgeführt wurden, gesammelt in einem Vorgang zur
        Abrechnung einreichen. Achtung: Reichen Sie jede Rechnung einzeln ein,
        tragen Sie für jeden Rechnungsbetrag {{ tarif.tier_sb_prozent }} %
        Selbstbeteiligung.
      </v-col>
    </v-row>
    <v-row v-for="item in filteredOrigin" :key="item.value">
      <v-col
        cols="12"
        md="12"
        xs="12"
        v-if="!item.showintern && tarif[item.value]"
      >
        <b>{{ item.title }}</b><br />
        <span class="pb-0 mb-0">
          {{ fieldStore.showFieldComputed(item.value, tarif, 'katze') }}</span
        >
        <a
          v-if="item.spezialpop && item.spezialpop == 'rechenweg'"
          @click="rechnerStore.setShowPopTarif(tarif, 'rechenwegkatze')"
        >
          &nbsp;&nbsp; mehr Infos...</a
        >

        <div class="dividersmall" />
      </v-col>
    </v-row>
    <v-btn
      v-if="tarif.un_abschluss_url != ''"
      class="nocaps my-4"
      style="background-color: #d84315; color: #fff"
    >
      <br />
      <a
        @click="saveAntragClickend()"
        :href="tarif.un_abschluss_url"
        target="_blank"
        style="color: #fff"
        >jetzt {{ tarif.un_name }} online beantragen
      </a>
    </v-btn>
    <div class="divider" />
    <IndexRechenweg :tierart="2" v-if="benchmark.length" :tarif="tarif" />

    <div class="divider" />
    <span v-if="tarif.tier_vergleich_nein == 0">
      <h3>Preis-Tabelle {{ tarif.tier_title }}:</h3>
      <br />

      Dies ist der Preis für die Krankenversicherung einer 1-jährigen Katze bei
      der {{ tarif.tier_title }}. Je jünger Ihre Katze beim Versicherungsabschluss
      ist desto günstiger ist der monatliche Beitrag.<br /><br />
      <v-table>
        <thead>
          <tr>
            <th class="text-left" style="min-width:120px;">Alter</th>
            <th class="text-left">Beitrag</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in preise" :key="item.preis_id">
            <td>{{ item.preis_alter }} Jahre</td>
            <td>{{ item.preis_value_katze }} €</td>
          </tr>
        </tbody>
      </v-table>
      <v-btn
        v-if="tarif.un_abschluss_url != ''"
        class="nocaps my-4"
        style="background-color: #d84315; color: #fff"
      >
        <br />
        <a
          @click="saveAntragClickend()"
          :href="tarif.un_abschluss_url"
          target="_blank"
          style="color: #fff"
          >jetzt {{ tarif.un_name }} online beantragen
        </a>
      </v-btn>
    </span>
    <span v-if="beispiele.length > 0">
      <h3>Erstattungsbeispiele:</h3>
      Reale Erstattungsbeispiele {{ tarif.tier_title }} Krankenversicherung
      <br />
      <v-row v-for="item in beispiele" :key="item.bei_id">
        <v-col cols="12" md="8">
          <b>{{ item.bei_title }}</b> <br />{{ item.bei_text }}
        </v-col>
        <v-col cols="12" md="2">
          <b>{{ item.bei_rechnung }} €</b>
          <br />
          <span class="caption">Tierarzt-Rechnung</span>
        </v-col>
        <v-col cols="12" md="2">
          <b class="text-green">{{ item.bei_erstattung }} €</b>
          <br />
          <span class="caption"
            >Erstattung durch {{ tarif.tier_title }}</span
          >
        </v-col>
      </v-row>
      <v-btn
        v-if="tarif.un_abschluss_url != ''"
        class="nocaps my-4"
        style="background-color: #d84315; color: #fff"
      >
        <br />
        <a
          @click="saveAntragClickend()"
          :href="tarif.un_abschluss_url"
          target="_blank"
          style="color: #fff"
          >jetzt {{ tarif.un_name }} online beantragen
        </a>
      </v-btn>
    </span>
    <span v-if="avb.length > 0">
      <div class="divider" />
      <h3>Dokumente & Downloads</h3>

      <ul>
        <li v-for="item in avb" :key="item.doc_id">
          <a :href="`/dist/tarif/${item.doc_dateiname}`" target="_blank">{{
            item.doc_text
          }}</a>
        </li>
      </ul>
    </span>
  </div>
</template>

<script setup>
import { defineProps, ref, computed, toRefs } from 'vue';
import { useFieldStore } from '@/stores/fields';
import IndexRechenweg from '@/components/rechner/IndexRechenweg.vue';
import { useBerechnungStore } from '@/stores/rechner';
const rechnerStore = useBerechnungStore();
import BaseCalls from '@/services/BaseCalls';

const fieldStore = useFieldStore();

const origin = ref(fieldStore.katze);

const props = defineProps({
  tarif: Object,
  testberichte: [Array, Boolean],
  preise: [Array, Boolean],
  avb: [Array, Boolean],
  benchmark: [Object, Boolean],
  beispiele: [Array, Boolean],
});

const { tarif, preise, avb, beispiele } = toRefs(props);

const filteredOrigin = computed(() => {
  return Object.values(origin.value).filter((item) => {
    return !item.showintern && tarif.value[item.value];
  });
});

async function saveAntragClickend() {
  let select = {
    action: 'saveclickantrag',
    tier_id: tarif.value.tier_id,
  };
  await BaseCalls.postTarif(select);
}
</script>

<style scoped>
.active-tab {
  background-color: #d84315 !important;
  color: #fff !important;
}
</style>