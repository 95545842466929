<template>
  <div>
    <v-row>
      <v-col cols="12" md="9" xs="12">
        <h1 class="mt-4">
          <span style="color: #d84315"
            >Die neue GOT 2023 kommt, und mit ihr deutlich höhere
            Tierarztkosten</span
          >
        </h1>

        <ul class="my-4">
          <li>Die neue GOT tritt am 22.11.2022 in Kraft</li>
          <li>
            Die neue GOT führt zu einer deutlichen Erhöhung von
            Tierarztrechnungen
          </li>
          <li>Diese Hundekrankenversicherungen leisten nach der neuen GOT</li>
        </ul>
        <h3>Was ist die GOT und was regelt sie?</h3>
        Die Gebührenordnung für Tierärztinnen und Tierärzte regelt die
        bundesweit einheitliche Vergütung von tierärztlichen Leistungen. Sie
        schützt damit auf der einen Seite Tierhalter vor überhöhten
        Tierarztrechnungen. Auf der anderen Seite gewährleistet die GOT, anhand
        einer angemessenen Vergütung, ein hohes Qualitätsniveau in der
        Veterinärmedizin. Zudem hilft sie Tierärzten, mit angemessenen Preisen
        den Fortbestand ihrer Tierarzt-Praxen zu sichern. Die letzte umfassende
        Änderung der GOT liegt bereits über 20 Jahre zurück. Eine Anpassung an
        die gestiegenen Kosten für Personal, medizinische Geräte und Verfahren
        war deshalb längst überfällig.<br /><br />
        <v-card ref="" outlined elevation="4" class="pa-4">
          <router-link to="/vergleich/starten">
            <a
              >Schließen Sie jetzt eine Krankenversicherung für Ihren Hund ab
              und ersparen Sie sich deutlich erhöhte Tierarztrechnungen.
              Vergleichen Sie jetzt!</a
            >
          </router-link>
        </v-card>
        <v-divider class="my-4"></v-divider>
        <h3>Tierarztrechnung - So werden Tierarztkosten berechnet</h3>
        Tierärzte stellen die Behandlung für Ihren Hund anhand der sog.
        Gebührenordnung für Tierärzte (GOT) in Rechnung. Die GOT ordnet den
        unterschiedlichen tierärztlichen Behandlungsschritten eine bestimmte
        Gebühr zu.
        <br />
        <br />
        <b>Beispiel:</b> Allgemeine Untersuchung mit Beratung, Hund, Katze,
        Frettchen = 23,62 € (1-facher Satz)
        <br />
        Je nachdem, wie anspruchsvoll dieser Behandlungsschritt im jeweiligen
        Einzelfall ist, kann der Tierarzt diese Gebühr mit dem 1-fachen bis
        3-fachen Satz abrechnen und damit dem Hundebesitzer zwischen 23,62 € und
        70,86 € in Rechnung stellen. Im Notdienst, also für Behandlungen, die
        bei Nacht, am Wochenende oder an einem Feiertag erbracht werden, kann
        der Tierarzt den 2-fachen bis 4-fachen Satz (d.h. bis zu 94,48 €) in
        Rechnung stellen. Hinzu kommt eine Notdienstgebühr in Höhe von 50 €.
        Zusätzlich zu den jeweiligen Behandlungsschritten werden dann noch
        Arzneimittel, Materialien, Laborleistungen und die Umsatzsteuer
        berechnet.
        <br /><br />
        <v-card ref="" outlined elevation="4" class="pa-4">
          <b>Hinweis:</b> Eine tierärztliche Behandlung besteht zumeist aus
          mehreren Behandlungsschritten. Das heißt, dass Sie immer mehrere GOT
          Positionen auf Ihrer Tierarztrechnung finden werden.
        </v-card>
        <br />
        <b>Beispiel:</b> Tierarztrechnung (nach neuer GOT 2023): Routinefall
        Behandlung einer Ohrenentzündung bei einem Hund <br /><br />
        <v-simple-table dense>
          <thead>
            <tr style="background-color:#f2f2f2">
              <th class="py-2" style="width:80px">Lfd. Nr.</th>
              <th class="py-2">Beschreibung GOT-Position</th>

              <th class="py-2" style="width:100px">
                GOT-Satz
              </th>
              <th class="py-2">
                Datum
              </th>
              <th class="py-2" style="width:120px">Preis in €</th>
            </tr>
          </thead>
          <tbody>
            <tr style="vertical-align:top">
              <td>16.</td>
              <td>
                Allgemeine Untersuchung mit Beratung, Hund, Katze, Frettchen
              </td>
              <td>1fach</td>
              <td>01.12.2022</td>
              <td class="text-right">23,62 €</td>
            </tr>
            <tr style="vertical-align:top">
              <td>657.</td>
              <td>Eingehende Untersuchung einzelner Organe des HNO-Bereichs</td>
              <td>1fach</td>
              <td>01.12.2022</td>
              <td class="text-right">17,25 €</td>
            </tr>
            <tr style="vertical-align:top">
              <td>147.</td>
              <td>
                Körperflüssigkeit, physikalische, chemische oder mikroskopische
                Untersuchung einfacher Art
              </td>
              <td>1fach</td>
              <td>01.12.2022</td>
              <td class="text-right">10,26 €</td>
            </tr>
            <tr style="vertical-align:top">
              <td>662.</td>
              <td>Otitis externa, Behandlung, je Seite</td>
              <td>1fach</td>
              <td>01.12.2022</td>
              <td class="text-right">10,26 €</td>
            </tr>
            <tr style="vertical-align:top">
              <td>210.</td>
              <td>Eingeben von Medikamenten</td>
              <td>1fach</td>
              <td>01.12.2022</td>
              <td class="text-right">4,13 €</td>
            </tr>
            <tr style="vertical-align:top">
              <td></td>
              <td>Ohrentropfen</td>
              <td></td>
              <td></td>
              <td class="text-right">28,71 €</td>
            </tr>
            <tr style="vertical-align:top">
              <td></td>
              <td>Zwischensumme</td>
              <td></td>
              <td></td>
              <td class="text-right">94,23 €</td>
            </tr>
            <tr style="vertical-align:top">
              <td></td>
              <td>+ 19 % Mehrwertsteuer</td>
              <td></td>
              <td></td>
              <td class="text-right">17,90 €</td>
            </tr>
            <tr style="vertical-align:top">
              <td></td>
              <td><b>Endsumme</b></td>
              <td></td>
              <td></td>
              <td class="text-right"><b>112,13 €</b></td>
            </tr>
          </tbody>
        </v-simple-table>
        <br />
        <h3>
          Wie viel darf und muss ein Tierarzt für seine Leistungen in Rechnung
          stellen?
        </h3>
        Tierärzte können ihre Leistungen mit dem 1-fachen bis 3-fachen Satz der
        GOT, im Notdienst mit dem 2-fachen bis 4-fachen Faktor des jeweiligen
        Gebührensatzes der GOT berechnen. Eine Unterschreitung des 1-fachen
        Satzes (im Notdienst des 2-fachen Satzes) ist grundsätzlich unzulässig.
        Auch eine Überschreitung des 3-fachen Satzes der Gebührenordnung für
        Tierärzte ist nur in begründeten Ausnahmefällen möglich und bedarf einer
        schriftlichen Vereinbarung zwischen Tierarzt und Hundebesitzer.

        <v-divider class="my-4"></v-divider>
        <h3>
          Welche Auswirkungen hat die neue Gebührenordnung für Tierärzte 2023
          auf Hundehalter?
        </h3>
        Tierarztrechnungen werden sich im Durchschnitt um 20 % - 30 % erhöhen.
        In der Neufassung der Gebührenordnung für Tierärzte 2023 wurden die
        Gebührensätze für einen großen Teil der Tierärztlichen Leistungen
        erhöht. Damit wird den Entwicklungen der letzten Jahre (wie z.B.
        Erhöhung der Personalkosten, Energiekosten, Kosten für Medizintechnik,
        etc.) Rechnung getragen. Diese Erhöhung der Gebührensätze wird zu einer
        durchschnittlichen Preiserhöhung von 20 % - 30 % für tierärztliche
        Behandlungen führen.
        <br />
        <router-link to="/vergleich/starten">
          <a>Vergleichen Sie jetzt die besten Hundekrankenversicherungen</a>
        </router-link>

        <v-divider class="my-4"></v-divider>
        <h3>
          Wie wirkt sich die neue Gebührenordnung für Tierärztinnen und
          Tierärzte auf die Leistungspraxis der Versicherungen aus?
        </h3>
        EisbaumTabelle hat bei führenden Hundekrankenversicherungen nachgefragt.
        Die Ergebnisse finden Sie im
        <a href="/images/pdf/kompass.pdf" target="_blank" class="greytext"
          >EisbaumTabelle | Leistungs-Kompass.</a
        >
        <v-divider class="my-4"></v-divider>
        <h3>
          Welche Rolle spielt der Satz der GOT für Hundekrankenversicherungen?
        </h3>
        Der Satz der Gebührenordnung für Tierärzte bestimmt maßgeblich die Höhe
        von Tierarztkosten. Aus diesem Grund beschränken einzelne Versicherungen
        ihre Leistungen anhand des GOT Satzes. Tarife, wie zum Beispiel der
        Gothaer Tierkrankenschutz Basis, leisten aus diesem Grund nur bis zu
        einem 2-fachen Satz der Gebührenordnung für Tierärzte. D.h. die
        restlichen Kosten muss der Hundebesitzer selbst tragen.
        <br /><br />
        <v-card ref="" outlined elevation="4" class="pa-4">
          <b>Hinweis:</b> Manche Versicherungen (wie z.B. Helvetia, SantéVet)
          verlangen ab einem 3-fachen GOT-Satz eine schriftliche Stellungnahme,
          warum zum diesem Satz verrechnet wurde.
        </v-card>
        <v-divider class="my-4"></v-divider>
        <h3>
          Führt die neue Gebührenordnung für Tierärzte 2023 zu starken
          Beitragsanpassungen bei Hundekrankenversicherungen?
        </h3>
        Da die neue Gebührenordnung für Tierärzte zu einer deutlichen Steigerung
        der Tierarztkosten führen wird, ist davon auszugehen, dass sich auch die
        Beiträge für Hundekrankenversicherungen kurz bzw. mittelfristig ändern
        werden. Allerdings gehen Branchenexperten von einer eher moderaten
        Erhöhung der Tierarztkosten aus. Hierfür sprechen drei Gründe:
        <ul class="my-4">
          <li>
            Auch Tierarztpraxen unterliegen einem gewissen Wettbewerb und können
            nicht sofort hohe Preissteigerungen durchsetzen
          </li>
          <li>
            Zudem wurden nicht alle Gebührenpositionen in der neuen GOT
            angehoben.
          </li>
          <li>
            Da sich die Einführung der neuen GOT bereits seit längerem
            abgezeichnet hat, haben einige Versicherer die höheren Kosten
            bereits “eingepreist”.
          </li>
        </ul>
        Dementsprechend werden einige Versicherungen (wie zum Beispiel die
        Barmenia, die Gothaer, die Agila) abwarten, wie sich die Tierarztkosten
        tatsächlich in Zukunft entwickeln und erst dann eine etwaige
        Beitragserhöhung in Erwägung ziehen.
      </v-col>
      <v-col cols="12" md="3" xs="12">
        <MenueDownload />
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import { onMounted,getCurrentInstance } from "vue";
import MenueDownload from "@/sites/base/MenueDownload.vue";
const { proxy } = getCurrentInstance();

onMounted(() => {

    proxy.$updateDocumentHead(
      "Diese Hundekrankenversicherungen leisten nach der neuen GOT 2023",
      "Deutlich höhere Tierarztrechnungen aufgrund der neuen Gebührenordnung für Tierärzte 2023 - Jetzt günstige Hundekrankenversicherung abschließen."
    );
  });
</script>
