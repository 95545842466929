<template>
  <div>
    <v-row class="my-4">
      <v-col cols="12" md="8">
        <div v-if="error">
          <template v-if="error == 1">
            <h2 class="text-red">
              Wir konnten leider keine passenden Versicherungen finden
            </h2>
          </template>
          <template v-else-if="error > 1">
            <h2 class="text-red">Fehler: Nr. {{ error }}</h2>
            <p>
              Bitte schreiben Sie uns eine E-Mail, damit wir unseren Rechner
              prüfen können. Eventuell liegt ein technischer Fehler vor.
            </p>
          </template>

          <div v-if="tarifenot && tarifenot.length > 0">
            <p>
              Wir haben für Sie folgende Tarife geprüft. Diese sind aber aufgrund
              Ihrer Berechnungsvorgaben nicht abschließbar.
            </p>
            <v-row v-for="item in tarifenot" :key="item.tier_id">
              <v-col cols="12" md="12">
                <b>{{ item.un_name }} {{ item.tier_title }}</b>
                <ul class="my-0">
                  <li v-for="info in item.nogoinfo" :key="info">{{ info }}</li>
                </ul>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'
const props = defineProps({
  tarifenot: {
    type: Array,
    default: () => [],
  },
  error: {
    type: [String, Number],
    default: 0,
  },
})
console.log(props.error);
</script>
