<template>
 <div class="pa-4">
    <h2 v-if="berechnung.ber_tierart <= 1">
      Vergleichen Sie bis zu 3 Hunde-Krankenversicherungen miteinander
    </h2>
    <h2 v-if="berechnung.ber_tierart == 2">
      Vergleichen Sie bis zu 3 Katzen-Krankenversicherungen miteinander
    </h2>
<v-row class="mb-4">
  <v-col cols="12" md="12">Wählen Sie jetzt noch 2 Tarife zum Vergleich aus.</v-col>
</v-row>
    <v-row>
      <v-col cols="12" md="3"> </v-col>
      <v-col cols="12" md="3"
        >Tarif 1: <br />

        <a style="font-size: 14px">{{ tarif1.tier_title }}</a></v-col
      >
      <v-col cols="12" md="3"
        >Tarif 2
        <select
          class="text-blue"
          style="padding-left: 4px; font-size: 14px;width:230px;"
          @change="pushTarifToVergleich($event, 2)"
        >
          <option value selected>Tarif 2 wählen</option>
          <option
            style="padding-left: 10px"
            v-for="item in alleTarife"
            :value="item.tier_id"
            :key="item.tier_id"
          >
            {{ item.tier_title }}
          </option>
        </select>
      </v-col>

      <v-col cols="12" md="3"
        >Tarif 3
        <select
          class="text-blue"
          style="padding-left: 4px; font-size: 14px;width:230px;"
          @change="pushTarifToVergleich($event, 3)"
        >
          <option value selected>Tarif 3 wählen</option>
          <option
            style="padding-left: 10px"
            v-for="item in alleTarife"
            :value="item.tier_id"
            :key="item.tier_id"
          >
            {{ item.tier_title }}
          </option>
        </select>
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col cols="12" md="12"> Tierwert {{ tarif2.tier_wert }} </v-col>
    </v-row> -->
    <v-row class="mb-4" style="background-color: #f2f2f2">
      <v-col cols="12" md="3" xs="12"><b>Monatsbeitrag</b> </v-col>
      <v-col cols="12" md="3" xs="12">
        {{ tarif1.preis }} €
        <span v-if="tarif1.zuschlag > 0" class="caption"
          ><br />inkl. Risiko-Zuschlag {{ tarif1.zuschlag }} €</span
        >
      </v-col>
      <v-col cols="12" md="3" xs="12">
        <span v-if="tarif2.preis">
          {{ tarif2.preis }} €
          <span v-if="tarif2.zuschlag > 0" class="caption"
            ><br />inkl. Risiko-Zuschlag {{ tarif2.zuschlag }} €</span
          >
        </span>
      </v-col>
      <v-col cols="12" md="3" xs="12">
        <span v-if="tarif3.preis">
          {{ tarif3.preis }} €
          <span v-if="tarif3.zuschlag > 0" class="caption"
            ><br />inkl. Risiko-Zuschlag {{ tarif3.zuschlag }} €</span
          >
        </span>
      </v-col>
    </v-row>
    <div v-for="(item, index) in filteredOrigin" :key="item.value">
      <v-row
        v-if="!item.showintern"
        class="mb-4"
        style="background-color: #f2f2f2"
      >
        <v-col cols="12" md="3" xs="12">
          <b>{{ item.title }}</b>
        </v-col>
        <v-col cols="12" md="3" xs="12">
          <span class="pb-0 mb-0">
            {{ fieldStore.showFieldComputed(item.value,tarif1, "hund") }}</span
          >
        </v-col>
        <v-col cols="12" md="3" xs="12">
          <span class="pb-0 mb-0" v-if="tarif2[item.value]">
            {{ fieldStore.showFieldComputed(item.value,tarif2, "hund") }}
          </span>
        </v-col>

        <v-col v-if="tarif3" cols="12" md="3" xs="12">
          <span class="pb-0 mb-0" v-if="tarif3[item.value]">
            {{ fieldStore.showFieldComputed(item.value, tarif3, "hund") }}</span
          >
        </v-col>
      </v-row>

      <!-- Display 'Abschluss' row after every 4 iterations -->
      <v-row v-if="(index + 1) % 4 === 0">
        <v-col cols="12" md="3" xs="12">
          <b>Abschluss</b>
        </v-col>
        <v-col cols="12" md="3" xs="12">
          <span v-if="tarif1.un_abschluss_url != ''">
          <a v-if="tarif1.un_abschluss_url"  :href="tarif1.un_abschluss_url">Online-Abschluss</a>
        </span>
          </v-col>
          <v-col cols="12" md="3" xs="12">
            <span v-if="tarif2.un_abschluss_url != ''">
          <a v-if="tarif2.un_abschluss_url"  :href="tarif2.un_abschluss_url">Online-Abschluss</a>
        </span>
          </v-col>
          <v-col cols="12" md="3" xs="12">
            <span v-if="tarif3.un_abschluss_url != ''">
          <a v-if="tarif3.un_abschluss_url"  :href="tarif3.un_abschluss_url">Online-Abschluss</a>
        </span>
          </v-col>

      </v-row>
    </div>
  </div>
</template>

<script setup>
import { defineProps,computed,ref} from "vue";
import { useFieldStore } from '@/stores/fields';
const fieldStore = useFieldStore();
const origin = ref(fieldStore.hund);
const filteredOrigin = computed(() => {
  return Object.values(origin.value).filter(item => {
    return !item.showintern && props.tarif[item.value];
  });
});

const props = defineProps({
  tarif: {
    type: Object,
    required: true,
  },
  alleTarife: {
    type: Array,
    required: true,
  },
  berechnung: {
    Object
  }
});

const tarif1 = ref(props.tarif);
const tarif2 = ref(false);
const tarif3 = ref(false);


function pushTarifToVergleich(event, tarif_nr) {
 
        for (var j = 0; j < props.alleTarife.length; j++) {
          if (props.alleTarife[j].tier_id == event.target.value) {
            if (tarif_nr == 2) {
              tarif2.value = props.alleTarife[j];
            }
            if (tarif_nr == 3) {
              tarif3.value = props.alleTarife[j];
            }
          }
        }
      }

</script>
