import { createApp } from "vue";
import App from "./App.vue";
import { createPinia } from "pinia";
const pinia = createPinia();
import router from "./router";
import vuetify from "./plugins/vuetify"; // Import der angepassten Vuetify-Instanz
import base from "./plugins/base";
import "./css/mystyle.css";

// loadFonts()

createApp(App)
  .use(pinia)
  .use(router)
  .use(base)
  .use(vuetify) // Verwendung von Vuetify
  .mount("#app");

