<template>
  <div>
    <v-card>
      <v-card-text class="pa-4">
        <h2>Diese Tarife haben wir analysiert</h2>
        Wir haben versucht alle Tarife am Markt zu analysieren. Wenn wir einen
        Tarif vergessen bzw. übersehen haben, schreiben Sie uns bitte eine
        E-Mail: info@eisbaumtabelle.de.<br /><br />

        <v-table>
          <thead>
            <tr style="background-color:#f2f2f2;vertical-align:top">
              <th class="py-2">Tarife</th>
              <th class="py-2">Ø-Erstattung</th>
              <th class="py-2">
                <span v-if="showTier == 'hund'">
                  mtl. Beitrag<br />
                  für 1-jährigen Dackel
                </span>
                <span v-if="showTier == 'katze'">
                  mtl. Beitrag<br />
                  für 1-jährige Katze
                </span>
              </th>

              <th class="py-2">Rechner</th>
            </tr>
          </thead>
          <tbody></tbody>
          <tr
            v-for="tarif in tarife"
            :key="tarif.tier_id"
            style="vertical-align:top"
          >
            <td class="py-2">
              <span v-if="showTier == 'hund'">
                <a v-if="tarif.tier_url"
                  ><router-link
                    :to="`/Krankenversicherung_Hund/${tarif.tier_url}`"
                    >{{ tarif.tier_title }}</router-link
                  >
                </a>
              </span>
              <span v-if="showTier == 'katze'">
                <a v-if="tarif.tier_url"
                  ><router-link
                    :to="`/Krankenversicherung_Katze/${tarif.tier_url}`"
                    >{{ tarif.tier_title }}</router-link
                  >
                </a>
              </span>
              <span v-if="tarif.tier_url == ''">{{ tarif.tier_title }}</span>
            </td>
            <td class="py-2">
              <span v-if="showTier == 'hund'">
                <span v-if="tarif.tier_wert > 0"
                  ><b>{{ tarif.tier_wert }} %</b></span
                >
                <span v-if="tarif.tier_wert == 0">noch nicht ausgewertet</span>
              </span>
              <span v-if="showTier == 'katze'">
                <span v-if="tarif.tier_wert_katze > 0"
                  ><b>{{ tarif.tier_wert_katze }} %</b></span
                >
                <span v-if="tarif.tier_wert_katze == 0"
                  >noch nicht ausgewertet</span
                >
              </span>
            </td>
            <td class="py-2">
              <span v-if="showTier == 'hund'">{{ tarif.preis_value_1 }}</span>
              <span v-if="showTier == 'katze'">{{
                tarif.preis_value_katze
              }}</span>

              €
            </td>

            <td class="py-2">
              <span v-if="tarif.tier_vergleich_nein == 0" class="text-green"
                >im Rechner</span
              >
              <span
                v-if="
                  tarif.tier_vergleich_nein > 0 &&
                    tarif.tier_vergleich_status == 1
                "
                class="orange--text"
                >wird gerade analysiert</span
              >
              <span
                v-if="
                  tarif.tier_vergleich_nein > 0 &&
                    tarif.tier_vergleich_status == 2
                "
                class="text-red"
                >Versicherung arbeitet nicht mit Vergleichsportalen</span
              >
              <span
                v-if="
                  tarif.tier_vergleich_nein > 0 &&
                    tarif.tier_vergleich_status == 3
                "
                class="purple--text"
                >Tarif wurde geschlossen</span
              >
            </td>
          </tr>
        </v-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script setup>
import { defineProps,ref,onMounted } from "vue";
import BaseCalls from "@/services/BaseCalls";

const tarife = ref(false);
const props = defineProps({
  tierart: {
    type: String
  },
});

const showTier = ref('hund');

async function getTarife() {
    let select = {
      action:  "getTarife",
      type: "no_vergleich",
      number: "array",
      type_no_vergleich: 1,
      tierart: props.tierart,
    };
    const resp = await BaseCalls.postTarif(select); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.tarife) {
        tarife.value = resp.data.tarife;
      }
    
  }

onMounted(() => {
  if (props.tierart == "hund") {
    showTier.value = "hund";
  }
  if (props.tierart == "katze") {
    showTier.value = "katze";
  }
  getTarife();
});

</script>