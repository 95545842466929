<template>
  <div class="ggfont pa-6">
    <span v-if="rechnerStore.berechnung.ber_id > 0" class="mt-6">
      <h4 class="redtext">Eisbaum Empfehlung für</h4>
      <RechnerName :berechnung="rechnerStore.berechnung" class="pb-2" />
      <ul>
        <li v-if="rechnerStore.berechnung.ber_emp_1_id > 0" class="mb-4">
          {{ rechnerStore.berechnung.ber_emp_1_name }}
          <sup class="redtext">Empfehlung</sup>
          <br /><b>
          <a  @click="saveAntragClickend(rechnerStore.berechnung.ber_emp_1_id,rechnerStore.berechnung.ber_emp_1_name)"
            :href="`/redirectabschluss/${rechnerStore.berechnung.ber_emp_1_id}`"
            target="_blank"
            >jetzt online beantragen</a
          ></b>
        </li>
        <li v-if="rechnerStore.berechnung.ber_emp_2_id > 0" class="mb-4">
          {{ rechnerStore.berechnung.ber_emp_2_name }} <sup  class="redtext">Alternative</sup>
          <br /><b>
          <a @click="saveAntragClickend(rechnerStore.berechnung.ber_emp_2_id,rechnerStore.berechnung.ber_emp_2_name)"
            :href="`/redirectabschluss/${rechnerStore.berechnung.ber_emp_2_id}`"
            target="_blank"
            >jetzt online beantragen</a>
</b>
        </li>
        <li>
          Vollständiges
          <a :href="`/vergleich/${rechnerStore.berechnung.ber_token}`"
            >Ergebnis anzeigen</a
          >
        </li>
      </ul>
      <div class="divider"></div>
    </span>

    <span v-else>
      <div class="divider"></div>
      <h4>Online-Vergleich starten <sup class="text-green">Tipp</sup></h4>
      <ul>
        <li>
          <router-link to="/rechner">Hunde-Krankenversicherungen</router-link>
        </li>
        <li>
          <router-link to="/vergleich_katze">Katzen-Krankenversicherungen</router-link>
        </li>
      </ul>
      <div class="divider"></div>
    </span>

    <h4>Für Tierärzte</h4>
    <ul>
      <li v-if="!userStore.user">
        <router-link to="/tierarzt">Als Tierarzt anmelden</router-link>
        <br />
        <span class="caption">
          <b class="redtext">Kostenlos</b> EisbaumTabellen bestellen
        </span>
      </li>
      <li v-else>
        <router-link to="/account">Neue Tabellen bestellen</router-link>
      </li>
      <li v-if="userStore.user">
        <router-link to="/stammdaten">Adresse & Passwort ändern</router-link>
      </li>
    </ul>
    <div class="divider"></div>

    <ul>
      <li><router-link to="/ueber_die_eisbaumtabelle" 
          >Über die EisbaumTabelle</router-link
        ></li>
      <li><router-link to="/impressum">Impressum</router-link></li>
    </ul>
  </div>
</template>

<script setup>
import { useBerechnungStore } from '@/stores/rechner';
import { useUserStore } from '@/stores/user';
import RechnerName from '@/sites/base/RechnerName.vue';
import BaseCalls from "@/services/BaseCalls";
const rechnerStore = useBerechnungStore();
const userStore = useUserStore();


async function saveAntragClickend(tier_id,tier_title) {

let select = {
       action: "saveclickantrag",
       tier_id: tier_id,
       ber_id: rechnerStore.berechnung.ber_id,
       name: tier_title,
     }
  await BaseCalls.postTarif(select); // Korrigiert, um .value zu verwenden

}
</script>
