<template>
  <div>
    <span v-if="erstattunggesamt == 0">
      <h3>
        Für {{ tarif.tier_title }} wurde noch keine Berechnung hinterlegt.
      </h3>
      Wir werden diese in den nächsten Tagen hinterlegen.
    </span>
    <span v-if="erstattunggesamt > 0 && showBer">
      <h3>
        {{ tarif.tier_title }} im Test | so wurde der Eisbaum Realwert ermittelt
      </h3>

      <v-row>
        <v-col cols="12" md="12"
          >Die {{ tarif.tier_title }}
          <span v-if="tierart == 1">Hunde-Krankenversicherung</span>
          <span v-if="tierart == 2">Katzen-Krankenversicherung</span>
          hat von
          <b class="text-red">{{ rechnungengesamt }},- €</b> angefallener
          Tierarztrechnungen (im Laufe von 5 Versicherungsjahren)
          <b class="text-green">{{ realwert.real_erstattung_gesamt }},- € </b>
          erstattet. Dies enstpricht einer Erstattung von
          <b class="text-green">{{ tarif.tier_wert }} %</b></v-col
        >
      </v-row>
      <br />

      <v-table>
        <tbody>
          <tr>
            <td><h3>Tierarzt Rechnungen im 1. Jahr</h3></td>
          </tr>
          <tr
            style="vertical-align:top;"
            v-for="rechnung in jahr1.rechnungen"
            :key="rechnung.nr"
          >
            <td>
              <b>{{ rechnung.nr }}. {{ rechnung.name }}</b>
              <ul>
                <li
                  v-for="position in rechnung.positionen"
                  :key="position.name"
                >
                  {{ position.name }}: <b>{{ position.kosten }},- €</b>
                </li>
              </ul>
            </td>
          </tr>
          <tr style="background-color:#f2f2f2">
            <td>
              Gesamt Tierarztkosten im 1. Jahr <b>{{ jahr1.kostengesamt }},-€</b
              ><br />
              Gesamt Erstattung durch {{ tarif.tier_title }} im 1. Jahr
              <b class="text-green">{{ realwert.real_erstattung_j1 }},-€</b>
            </td>
          </tr>
          <tr>
            <td><h3 class="mt-8">Tierarzt Rechnungen im 2. Jahr</h3></td>
          </tr>
          <tr
            style="vertical-align:top;"
            v-for="rechnung in jahr2.rechnungen"
            :key="rechnung.nr"
          >
            <td>
              <b>{{ rechnung.nr }}. {{ rechnung.name }}</b>
              <ul>
                <li
                  v-for="position in rechnung.positionen"
                  :key="position.name"
                >
                  {{ position.name }}: <b>{{ position.kosten }},- €</b>
                </li>
              </ul>
            </td>
          </tr>

          <tr style="background-color:#f2f2f2">
            <td>
              Gesamt Tierarztkosten im 2. Jahr <b>{{ jahr2.kostengesamt }},-€</b
              ><br />
              Gesamt Erstattung durch {{ tarif.tier_title }} im 2. Jahr
              <b class="text-green">{{ realwert.real_erstattung_j2 }},-€</b>
            </td>
          </tr>

          <tr>
            <td><h3 class="mt-8">Tierarzt Rechnungen im 3. Jahr</h3></td>
          </tr>
          <tr
            style="vertical-align:top;"
            v-for="rechnung in jahr3.rechnungen"
            :key="rechnung.nr"
          >
            <td>
              <b>{{ rechnung.nr }}. {{ rechnung.name }}</b>
              <ul>
                <li
                  v-for="position in rechnung.positionen"
                  :key="position.name"
                >
                  {{ position.name }}: <b>{{ position.kosten }},- €</b>
                </li>
              </ul>
            </td>
          </tr>

          <tr style="background-color:#f2f2f2">
            <td>
              Gesamt Tierarztkosten im 3. Jahr <b>{{ jahr3.kostengesamt }},-€</b
              ><br />
              Gesamt Erstattung durch {{ tarif.tier_title }} im 3. Jahr
              <b class="text-green">{{ realwert.real_erstattung_j3 }},-€</b>
            </td>
          </tr>

          <tr>
            <td><h3 class="mt-8">Tierarzt Rechnungen im 4. Jahr</h3></td>
          </tr>
          <tr
            style="vertical-align:top;"
            v-for="rechnung in jahr4.rechnungen"
            :key="rechnung.nr"
          >
            <td>
              <b>{{ rechnung.nr }}. {{ rechnung.name }}</b>
              <ul>
                <li
                  v-for="position in rechnung.positionen"
                  :key="position.name"
                >
                  {{ position.name }}: <b>{{ position.kosten }},- €</b>
                </li>
              </ul>
            </td>
          </tr>

          <tr style="background-color:#f2f2f2">
            <td>
              Gesamt Tierarztkosten im 4. Jahr <b>{{ jahr4.kostengesamt }},-€</b
              ><br />
              Gesamt Erstattung durch {{ tarif.tier_title }} im 4. Jahr
              <b class="text-green">{{ realwert.real_erstattung_j4 }},-€</b>
            </td>
          </tr>

          <tr>
            <td><h3 class="mt-8">Tierarzt Rechnungen im 5. Jahr</h3></td>
          </tr>
          <tr
            style="vertical-align:top;"
            v-for="rechnung in jahr5.rechnungen"
            :key="rechnung.nr"
          >
            <td>
              <b>{{ rechnung.nr }}. {{ rechnung.name }}</b>
              <ul>
                <li
                  v-for="position in rechnung.positionen"
                  :key="position.name"
                >
                  {{ position.name }}: <b>{{ position.kosten }},- €</b>
                </li>
              </ul>
            </td>
          </tr>

          <tr style="background-color:#f2f2f2">
            <td>
              Gesamt Tierarztkosten im 5. Jahr <b>{{ jahr5.kostengesamt }},-€</b
              ><br />
              Gesamt Erstattung durch {{ tarif.tier_title }} im 5. Jahr
              <b class="text-green">{{ realwert.real_erstattung_j5 }},-€</b>
            </td>
          </tr>
        </tbody>
      </v-table>
    </span>
  </div>
</template>
<script setup>
import { ref,defineProps, onMounted } from "vue";
import BaseCalls from "@/services/BaseCalls";
  const props = defineProps({
  tarif: {
    type: Object,
    required: true,
  },
  tierart: {
   
  },
});

const showBer = ref(true);
const jahr1 = ref(0);
const jahr2 = ref(0);
const jahr3 = ref(0);
const jahr4 = ref(0);
const jahr5 = ref(0);
const erstattunggesamt = ref(0);
const rechnungengesamt = ref(0);
const realwert = ref(false);


async function getTarifBenchmark() {
    let select = {
      action:  "getTarifBenchmark",
      tierart: props.tierart,
      id: props.tarif.tier_id,
    };
   
    const resp = await BaseCalls.postTarif(select); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data) {
          showBer.value = true;
          jahr1.value = resp.data.jahr1;
          jahr2.value = resp.data.jahr2;
          jahr3.value = resp.data.jahr3;
          jahr4.value = resp.data.jahr4;
          jahr5.value = resp.data.jahr5;
          erstattunggesamt.value = resp.data.erstattunggesamt;
          rechnungengesamt.value = resp.data.rechnungengesamt;
      }
  }

  async function getRealwert() {
    let select = {
      action:  "getRealwert",
      tierart: props.tierart,
      id: props.tarif.tier_id,
    };
   
    const resp = await BaseCalls.postTarif(select); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data) {
        realwert.value = resp.data.realwert;
  }
}

onMounted(() => {
  getTarifBenchmark();
  getRealwert();
});



</script>
